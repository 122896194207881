import { useState, type FC } from "react";
import { cn } from "@/components/podkit/lib/cn";
import { Heading1 } from "@/components/podkit/typography/Headings";
import { Link } from "react-router-dom";
import { type PlainProject, type ProjectState } from "@/queries/project-queries";
import { ProjectDetails } from "@/routes/projects/details/ProjectConstants";
import { ProjectDetailsForm } from "@/routes/projects/details/ProjectDetailsForm";

export const ProjectsDetailsModalLayout: FC<{ project: PlainProject; state: ProjectState; onSuccess: () => void }> = ({
    project,
    state,
    onSuccess,
}) => {
    const [activeMenuItem, setActiveMenuItem] = useState<ProjectDetails>(ProjectDetails.Settings);

    return (
        <div className="h-ull flex h-full overflow-hidden">
            <ProjectDetailsSidebar
                project={project}
                activeMenuItem={activeMenuItem}
                onMenuItemClick={(v) => setActiveMenuItem(v as ProjectDetails)}
            />
            <main className="grow overflow-hidden py-6 pl-6 pr-10">
                <ProjectDetailsForm
                    project={project}
                    state={state}
                    activeProjectDetails={activeMenuItem}
                    onSuccess={onSuccess}
                />
            </main>
        </div>
    );
};

export const ProjectDetailsSidebar: FC<{
    project: PlainProject;
    activeMenuItem: ProjectDetails;
    onMenuItemClick: (label: string) => void;
}> = ({ project, activeMenuItem, onMenuItemClick }) => {
    return (
        <div className="flex w-[220px] min-w-[220px] flex-col items-start gap-1 overflow-hidden border-r border-border-light px-4 py-6">
            <div className="w-full min-w-0 p-2 pb-2 pt-1">
                <Heading1>{project.metadata?.name}</Heading1>
            </div>
            <MenuItem
                label={ProjectDetails.Settings}
                onClick={onMenuItemClick}
                active={activeMenuItem === ProjectDetails.Settings}
            />
            <MenuItem
                label={ProjectDetails.DevelopmentContainer}
                onClick={onMenuItemClick}
                active={activeMenuItem === ProjectDetails.DevelopmentContainer}
            />
            <MenuItem
                label={ProjectDetails.Automations}
                onClick={onMenuItemClick}
                active={activeMenuItem === ProjectDetails.Automations}
            />
            <MenuItem
                label={ProjectDetails.Sharing}
                onClick={onMenuItemClick}
                active={activeMenuItem === ProjectDetails.Sharing}
            />
        </div>
    );
};

const MenuItem: FC<{ label: string; active: boolean; onClick: (label: string) => void }> = ({
    label,
    active,
    onClick,
}) => {
    const classNames =
        "w-full text-left rounded-md p-2 text-base whitespace-nowrap hover:bg-surface-tertiary hover:text-content-primary text-content-tertiary  ";
    if (active) {
        return <div className={cn(classNames, "min-w-44 text-content-primary")}>{label}</div>;
    } else {
        return (
            <Link
                to="#"
                className={classNames}
                onClick={() => {
                    onClick(label);
                }}
            >
                {label}
            </Link>
        );
    }
};
