import { Input } from "@/components/podkit/forms/Input";
import { InputField } from "@/components/podkit/forms/InputField";
import { Textarea } from "@/components/podkit/forms/Textarea";
import { useCallback, useEffect, useId, useState, type FC } from "react";

export type NewSecretFormOnSubmitInput = { secretName: string; secretValuePlaintext: string };

export const NewSecretForm: FC<{
    formName: string;
    onDisabledChange: (disabled: boolean) => void;
    onSubmit: (values: NewSecretFormOnSubmitInput) => Promise<void>;
}> = ({ formName, onSubmit, onDisabledChange }) => {
    const nameId = useId();
    const secretId = useId();

    const [name, setName] = useState<string>("");
    const [secret, setSecret] = useState<string>("");

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            await onSubmit({ secretName: name, secretValuePlaintext: secret });
        },
        [onSubmit, name, secret],
    );

    useEffect(() => {
        if (!name || !secret) {
            onDisabledChange(true);
            return;
        }
        onDisabledChange(false);
    }, [name, secret, onDisabledChange]);

    return (
        <form
            onSubmit={handleSubmit}
            name={formName}
            id={formName}
            data-testid="new-secret-form"
            className="flex flex-col gap-4"
        >
            <InputField label="Name" id={nameId}>
                <Input
                    id={nameId}
                    type="text"
                    name="secret-name"
                    value={name || ""}
                    placeholder="Ex: MY_SECRET"
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    data-testid="secret-name-input"
                />
            </InputField>

            <InputField id={nameId} label="Secret">
                <Textarea
                    id={secretId}
                    name="secret-value"
                    rows={10}
                    value={secret || ""}
                    placeholder="Enter your secret"
                    onChange={(e) => setSecret(e.target.value)}
                    className="resize-none"
                    data-testid="secret-value-input"
                />
            </InputField>
        </form>
    );
};
