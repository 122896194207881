import { type FC } from "react";
import { Dialog, DialogContent } from "@/components/podkit/modal/Modal";
import { type PlainProject, type ProjectState } from "@/queries/project-queries";
import type { PlainOrganizationMember } from "@/queries/organization-queries";
import { ProjectsDetailsModalLayout } from "@/routes/projects/details/ProjectsDetailsModalLayout";

type EditProjectModalProps = {
    project: PlainProject;
    state: ProjectState;
    creator: PlainOrganizationMember | undefined;
    currentMember: PlainOrganizationMember | undefined;
    onClose: () => void;
};

export const EditProjectModal: FC<EditProjectModalProps> = ({ project, state, onClose }: EditProjectModalProps) => {
    const onOpenChange = (open: boolean) => {
        if (!open) {
            onClose();
        }
    };

    return (
        <Dialog open onOpenChange={onOpenChange}>
            <DialogContent className="h-[470px] w-[768px] max-w-[768px] p-0">
                <ProjectsDetailsModalLayout project={project} state={state} onSuccess={() => onClose()} />
            </DialogContent>
        </Dialog>
    );
};
