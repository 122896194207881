import type { FC } from "react";
import { Heading1 } from "@/components/podkit/typography/Headings";
import { useProject } from "@/queries/project-queries";
import { SkeletonText } from "@/components/podkit/loading/Skeleton";
import { ErrorMessage } from "@/components/ErrorMessage";

export const ProjectHeader: FC<{ projectId: string }> = ({ projectId }) => {
    const { data: project, isPending, error } = useProject(projectId);
    return (
        <div>
            <SkeletonText size="lg" ready={!isPending} failed={!!error} className="h-7 w-80">
                <ErrorMessage error={error} />
                <Heading1 className="text-2xl font-bold text-content-primary">{project?.metadata?.name}</Heading1>
            </SkeletonText>
        </div>
    );
};
