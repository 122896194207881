import type React from "react";
import { useCallback } from "react";
import { cn } from "@/components/podkit/lib/cn";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import { Combobox, ComboboxProvider, ComboboxItem, ComboboxPopover } from "@ariakit/react";

type Props = {
    id?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    name?: string;
    onChange?: (value: string) => void;
    onBlur?: () => void;
    placeholder?: string;
    suggestions: string[];
    value?: string;
    error?: string;
    "data-testid"?: string;
} & PropsWithClassName;

const InputWithSuggestions: React.FC<Props> = ({
    id,
    autoFocus,
    disabled,
    name,
    onChange,
    onBlur,
    placeholder,
    suggestions,
    value,
    error,
    "data-testid": testId,
}) => {
    const updateValue = (value: string) => {
        if (onChange) {
            onChange(value);
        }
    };

    const filteredSuggestions = suggestions.filter(
        (item) => !value || item.toLowerCase().includes(value.toLowerCase()),
    );
    const hasSuggestions = filteredSuggestions.length > 0;

    const handleBlur = useCallback(() => {
        if (onBlur) {
            onBlur();
        }
    }, [onBlur]);

    return (
        <ComboboxProvider setValue={updateValue} value={value}>
            <Combobox
                id={id}
                placeholder={placeholder}
                className={cn(
                    "flex h-9 w-full rounded-lg border border-border-light bg-surface-secondary px-3 py-2 text-base ring-offset-surface-primary",
                    "file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-content-tertiary",
                    "disabled:cursor-not-allowed disabled:opacity-50",
                    error ? "border-content-negative focus-visible:border-border-light" : "border-border-light",
                )}
                autoFocus={autoFocus}
                disabled={disabled}
                name={name}
                onBlur={handleBlur}
                data-testid={testId}
            />
            {hasSuggestions && (
                <ComboboxPopover
                    gutter={8}
                    sameWidth
                    className={cn(
                        "z-10 w-full overflow-hidden rounded-lg border border-border-light bg-surface-secondary",
                    )}
                >
                    {filteredSuggestions.map((value) => (
                        <ComboboxItem
                            key={value}
                            value={value}
                            className={cn(
                                "cursor-pointer px-3 py-2 text-base opacity-100",
                                "hover:bg-surface-tertiary",
                                "data-[active-item=true]:bg-surface-tertiary data-[active-item=true]:text-content-primary data-[active-item=true]:ring-0",
                            )}
                        />
                    ))}
                </ComboboxPopover>
            )}
            {error && <div className="text-sm text-content-negative">{error}</div>}
        </ComboboxProvider>
    );
};

export default InputWithSuggestions;
