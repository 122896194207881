// ensure to bind ipc as first thing
import "./ipc";

// then import other things
import { Toaster } from "@/components/podkit/toasts/Toaster";
import { GitpodAPIProvider } from "@/contexts/GitpodAPIContext";
import { restorePrincipal } from "@/principal";
import { OpenFeature, OpenFeatureProvider } from "@openfeature/react-sdk";
import { QueryClient, QueryClientProvider, type QueryCacheNotifyEvent } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import ReactDOM from "react-dom/client";
import { IntercomProvider } from "react-use-intercom";
import { ThemeProvider } from "./contexts/ThemeProvider";
import "./index.css";
import { getAccountQueryKey, type PlainAccount } from "@/queries/account-queries";
import { SegmentProvider } from "@/contexts/SegmentProvider";
import { createSegment } from "@/contexts/SegmentContext";
import { ConfigCatWebProvider } from "@openfeature/config-cat-web-provider";
import { App } from "@/app";
import { OnboardingProvider } from "@/contexts/OnboardingProvider";
import { OnboardingQuestionnaireProvider } from "@/contexts/OnboardingQuestionnaireProvider";

// Restoring principal from local storage before warming up the query client here.
restorePrincipal();

const provider = ConfigCatWebProvider.create("configcat-proxy/default", {
    setupHooks: (hooks) => hooks.on("clientReady", () => console.log("ConfigCat client is ready!")),
    baseUrl: window.origin + "/feature-flags/configcat",
});
provider
    .initialize()
    .then(() => {
        OpenFeature.setProvider(provider);
    })
    .catch(console.error);
const client = OpenFeature.getClient();

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
    queries: {
        refetchOnReconnect: true,
        staleTime: 1_000 * 5, // 5 seconds
    },
});

// Track the current account and update the feature flag context
queryClient.getQueryCache().subscribe((event: QueryCacheNotifyEvent) => {
    if (event.query.queryKey === getAccountQueryKey()) {
        if (event.query.state.data) {
            const account = event.query.state.data as PlainAccount | undefined;
            if (account) {
                if (OpenFeature.getContext().targetingKey !== account.id) {
                    OpenFeature.setContext({
                        targetingKey: account.id,
                        custom: {},
                        email: account.email,
                    }).catch(console.error);
                }
            }
        }
    }
});

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <SegmentProvider create={() => createSegment()}>
            <OpenFeatureProvider client={client}>
                <IntercomProvider appId="ax8s6m84" apiBase="https://api-iam.intercom.io" initializeDelay={2000}>
                    <ThemeProvider>
                        <GitpodAPIProvider>
                            <QueryClientProvider client={queryClient}>
                                <OnboardingQuestionnaireProvider>
                                    <OnboardingProvider>
                                        <ReactQueryDevtools initialIsOpen={false} />
                                        <App />
                                        <Toaster />
                                    </OnboardingProvider>
                                </OnboardingQuestionnaireProvider>
                            </QueryClientProvider>
                        </GitpodAPIProvider>
                    </ThemeProvider>
                </IntercomProvider>
            </OpenFeatureProvider>
        </SegmentProvider>
    </React.StrictMode>,
);
