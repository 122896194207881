import { Text } from "@/components/podkit/typography/Text";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { InviteMembers } from "@/routes/settings/organization/members/InviteMembersPage";
import { OnboardingTabContent } from "@/routes/onboarding/components/OnboardingTabContent";
import { type FC } from "react";
import { Link } from "react-router-dom";

export const InvitePeoplePage: FC = () => {
    useDocumentTitle("Onboarding - Invite people to your team");
    return (
        <OnboardingTabContent title="Share with your team">
            <InviteMembers />
            <Text className="text-xs text-content-primary">
                <span className="font-bold">Tip</span>: You can do this later by navigating to{" "}
                <Link to="/settings/members" className="font-bold text-content-orange">
                    Settings &gt; Members
                </Link>
                .
            </Text>
        </OnboardingTabContent>
    );
};
