import { Heading1, Subheading } from "@/components/podkit/typography/Headings";
import type { FC, PropsWithChildren } from "react";

export const OnboardingTabContent: FC<
    { title?: string; description?: string; showDivider?: boolean; learnMoreLink?: string } & PropsWithChildren
> = ({ title, description, children, showDivider = true, learnMoreLink }) => {
    return (
        <div className="flex flex-col gap-8 rounded-xl border border-border-base bg-surface-glass p-6">
            {title || description ? (
                <div className="flex flex-col justify-between overflow-hidden lg:flex-row lg:gap-6">
                    <Heading1 className="whitespace-nowrap text-wrap text-2xl font-normal text-content-primary lg:w-1/2">
                        {title}
                    </Heading1>
                    <div className="flex-shrink text-wrap lg:w-1/2">
                        <Subheading className="text-xl font-normal text-content-tertiary">{description}</Subheading>
                        {learnMoreLink && (
                            <p className="text-content-orange">
                                <a href={learnMoreLink} target="_blank" rel="noreferrer" data-track-label="true">
                                    Learn more.
                                </a>
                            </p>
                        )}
                    </div>
                </div>
            ) : null}
            {showDivider && <div className="h-1 w-full border-t border-dashed border-border-base" />}
            {children}
        </div>
    );
};
