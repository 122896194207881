import { cn } from "@/components/podkit/lib/cn";
import { useMembership } from "@/hooks/use-membership";
import type { MenuItem } from "@/menu-item";
import { OrganizationRole } from "gitpod-next-api/gitpod/v1/organization_pb";
import type { FC } from "react";
import { Link, useLocation } from "react-router-dom";

export const SettingsMenu: FC<{ menu: MenuItem[] }> = ({ menu }) => {
    const { membership } = useMembership();

    const isOrgAdmin = membership?.userRole === OrganizationRole.ADMIN;
    const items = menu.filter((entry) => isOrgAdmin || !entry.isAdminRole);
    const sections = [...new Set(items.map((item) => item.section || ""))];
    const itemsBySection = new Map(
        sections.map((section) => [section, items.filter((item) => item.section === section)]),
    );

    return (
        <div className="flex flex-col gap-4">
            {sections.map((section) => (
                <div key={section} className="flex w-full flex-col gap-1 pl-8" translate="no">
                    <div className="select-none p-2 text-base font-bold text-content-tertiary">{section}</div>

                    {itemsBySection
                        .get(section)
                        ?.map((item) => <MenuItemComponent label={item.label} to={item.to} key={item.to} />)}
                </div>
            ))}
        </div>
    );
};

const MenuItemComponent: FC<{ label: string; to: string }> = ({ label, to }) => {
    const location = useLocation();
    const active = location.pathname.includes(to);

    return (
        <Link
            to={to}
            className={cn(
                "flex h-[36px] w-full select-none items-center whitespace-nowrap rounded-lg p-2 text-left text-base text-content-primary hover:bg-surface-secondary",
                active && "min-w-44 select-none bg-surface-secondary text-content-primary",
            )}
            translate="no"
        >
            {label}
        </Link>
    );
};
