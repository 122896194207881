import { IconBox } from "@/assets/icons/geist/IconBox";
import { IconBoxLink } from "@/assets/icons/geist/IconBoxLink";
import { IconBranch } from "@/assets/icons/geist/IconBranch";
import { IconDot } from "@/assets/icons/geist/IconDot";
import { IconNewEnvironment } from "@/assets/icons/geist/IconNewEnvironment";
import { IconVSCode } from "@/assets/icons/geist/IconVSCode";
import { Collapsable } from "@/components/Collapsable";
import { Button } from "@/components/flexkit/Button";
import { cn } from "@/components/podkit/lib/cn";
import { SkeletonText } from "@/components/podkit/loading/Skeleton";
import { Tooltip } from "@/components/Tooltip";
import { useEnvironmentsByProjects, type EnvironmentsGroup } from "@/hooks/use-environments-by-projects";
import { useOpenEditor } from "@/hooks/use-open-editor";
import { type PlainEnvironment } from "@/queries/environment-queries";
import { CreateEnvironmentButton } from "@/routes/environments/create/CreateEnvironmentButton";
import { getDetailsURL } from "@/routes/environments/details-url";
import { canOpen, effectiveState, type EffectiveState } from "@/routes/environments/phase";
import { EnvironmentPhase } from "gitpod-next-api/gitpod/v1/environment_pb";
import { useCallback, useEffect, useMemo, useState, type FC } from "react";
import { Link, useParams } from "react-router-dom";

export const SidebarEnvironmentList: FC = () => {
    const { data: envsByProject, isLoading } = useEnvironmentsByProjects();

    if (isLoading) {
        return null;
    }
    return (
        <div data-testid="environments-list">
            {envsByProject.map((group) => (
                <div key={`group-${group.projectId || group.repoFullName}`} className="pt-2">
                    <Group group={group} />
                </div>
            ))}
        </div>
    );
};

const Group: FC<{
    group: EnvironmentsGroup;
}> = ({ group }) => {
    const { environmentId } = useParams();
    const shouldOpen = useMemo(() => {
        if (environmentId) {
            if (group.environments.some((env) => env.id === environmentId)) {
                return true;
            }
        }
        return group.environments.some(
            (env) =>
                env.status?.phase && [EnvironmentPhase.RUNNING, EnvironmentPhase.STARTING].includes(env.status.phase),
        );
    }, [environmentId, group.environments]);
    const [openState, setOpenState] = useState<"open" | "closed">(shouldOpen ? "open" : "closed");

    /**
     * When the group's `shouldOpen` computed state changes to true, we want to open the group.
     */
    useEffect(() => {
        if (shouldOpen) {
            setOpenState("open");
        }
    }, [shouldOpen]);

    const toggleOpen = useCallback(() => {
        setOpenState((current) => (current === "open" ? "closed" : "open"));
    }, []);

    return (
        <>
            <div className="group my-1 flex h-9 w-full select-none flex-row content-center items-center justify-between rounded-lg hover:bg-surface-03">
                <div className="flex-grow overflow-hidden">
                    <button
                        onClick={toggleOpen}
                        className="flex w-full select-none flex-row content-center items-center"
                    >
                        <div className="flex pl-2">
                            {group.project ? <IconBox state={openState} /> : <IconBoxLink state={openState} />}
                        </div>
                        <div className="truncate pl-2 text-base" translate="no">
                            {group.repoFullName ? (
                                group.repoFullName
                            ) : group.projectMissing ? (
                                "Deleted project"
                            ) : (
                                <SkeletonText size="base" className="w-32" ready={!!group.project}>
                                    {group.project?.metadata?.name || "Untitled project"}
                                </SkeletonText>
                            )}
                        </div>
                    </button>
                </div>
                {group.project && (
                    <div className="mr-2 flex-shrink-0 opacity-0 group-hover:opacity-100">
                        <Tooltip content="Create environment">
                            <CreateEnvironmentButton
                                project={group.project}
                                LeadingIcon={IconNewEnvironment}
                                variant={"link"}
                                size={"sm"}
                                className="text-content-secondary hover:text-content-primary"
                                onCreateSuccess={() => setOpenState("open")}
                            />
                        </Tooltip>
                    </div>
                )}
            </div>
            <Collapsable collapsed={openState === "closed"}>
                {group.environments.map((env) => (
                    <Env key={env.id} env={env} active={location.pathname.includes("/" + env.id)} />
                ))}
            </Collapsable>
        </>
    );
};

const Env: FC<{ env: PlainEnvironment; active?: boolean }> = (p) => {
    const handleOpen = useOpenEditor(p.env);
    const branch = useMemo(() => {
        if (!p.env.status?.content?.git?.branch) {
            return "main";
        }
        return p.env.status?.content?.git?.branch;
    }, [p.env]);
    let label = branch;
    const failureMessages = p.env.status?.failureMessage || [];
    const hasFailures = failureMessages.length > 0;
    if (!label && hasFailures) {
        label = "no branch";
    }

    const commits = p.env.status?.content?.git?.totalUnpushedCommits || 0;

    const state = useMemo(() => effectiveState(p.env), [p.env]);

    return (
        <Link
            className={cn(
                "group my-1 flex h-9 w-full select-none flex-row content-center items-center rounded-lg",
                p.active ? "bg-surface-secondary" : "hover:bg-surface-03",
            )}
            to={getDetailsURL(p.env)}
        >
            <div className="min-w-8 pl-2">{state && <EnvironmentStatusIndicator state={state} />}</div>
            <div className="flex flex-grow items-center gap-2 truncate pl-2 text-base">
                <SkeletonText size="base" className="w-32" ready={!!label}>
                    <>
                        <span className="truncate" translate="no">
                            {label}
                        </span>
                    </>
                </SkeletonText>
            </div>
            <div className="hidden gap-1 px-2 group-hover:flex">
                <Button
                    disabled={!canOpen(p.env)}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleOpen();
                    }}
                    LeadingIcon={IconVSCode}
                    size="sm"
                    variant="link"
                    className={cn(
                        "text-content-secondary hover:text-content-primary",
                        p.active && "hover:text-content-primary",
                        !canOpen(p.env) && "hidden",
                    )}
                />
            </div>
            {commits > 0 && (
                <Tooltip content={`Unpushed commits: ${commits}`}>
                    <div className="flex flex-row rounded-lg px-2 py-1 text-center align-middle text-base group-hover:hidden">
                        <IconBranch size="base" />
                        {commits}
                    </div>
                </Tooltip>
            )}
        </Link>
    );
};

const EnvironmentStatusIndicator: FC<{
    state: EffectiveState;
}> = ({ state }) => {
    const { color } = useMemo(() => {
        let color = "text-content-tertiary size-2 m-2";

        switch (state.state) {
            case EnvironmentPhase.RUNNING:
                color = "text-content-green";
                break;
            case EnvironmentPhase.UPDATING:
                color = "text-content-tertiary";
                break;
            case EnvironmentPhase.STARTING:
            case EnvironmentPhase.CREATING:
            case EnvironmentPhase.STOPPING:
                color = "text-content-orange";
                break;
            case "OFFLINE":
            case EnvironmentPhase.STOPPED:
                if (state.failures) {
                    color = "text-content-red";
                } else {
                    color = "text-content-tertiary size-2 m-2";
                }
                break;
            case EnvironmentPhase.DELETING:
                color = "text-content-gray-600 opacity-50";
                break;
        }
        return {
            color,
            ...state,
        };
    }, [state]);

    return (
        <>
            <IconDot size="lg" className={color} />
        </>
    );
};
