import { Button } from "@/components/flexkit/Button";
import { Card } from "@/components/podkit/Card";
import { DropdownMenuItem } from "@/components/podkit/dropdown/DropDown";
import { DropdownActions } from "@/components/podkit/dropdown/DropDownActions";
import { RadioGroup, RadioGroupItem } from "@/components/podkit/forms/RadioListField";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { Table, TableBody, TableCell, TableHeader, TableRow } from "@/components/podkit/tables/Table";
import { toast } from "@/components/podkit/toasts/use-toast";
import { Text } from "@/components/podkit/typography/Text";
import {
    roleName,
    useLeaveOrganization,
    useSetRole,
    type PlainOrganizationMember,
} from "@/queries/organization-queries";
import { useSuspendUser } from "@/queries/user-queries";
import { formatError } from "@/utils/errors";
import { Timestamp, type PlainMessage } from "@bufbuild/protobuf";
import { DialogClose } from "@radix-ui/react-dialog";
import type { AccountMembership } from "gitpod-next-api/gitpod/v1/account_pb";
import { OrganizationRole, type OrganizationMember } from "gitpod-next-api/gitpod/v1/organization_pb";
import type { PaginationResponse } from "gitpod-next-api/gitpod/v1/pagination_pb";
import type { User } from "gitpod-next-api/gitpod/v1/user_pb";
import { UserStatus } from "gitpod-next-api/gitpod/v1/user_pb";
import { useCallback, useMemo, useState, type FC } from "react";
import { useNavigate } from "react-router-dom";

export type MembersListProps = {
    user?: PlainMessage<User>;
    membership?: PlainMessage<AccountMembership>;
    data: {
        members: PlainMessage<OrganizationMember>[];
        pagination: PaginationResponse | undefined;
    };
    onInviteMembers?: () => void;
};

export const MembersList: FC<MembersListProps> = ({ user, membership, data, onInviteMembers }) => {
    const isAdmin = membership?.userRole === OrganizationRole.ADMIN;

    if (!data.members || !user || !membership) {
        return <LoadingState />;
    }

    return (
        <>
            {isAdmin && (
                <div className="flex items-center justify-between pb-6">
                    <div />
                    <Button
                        disabled={!isAdmin || !onInviteMembers}
                        variant="secondary"
                        /* compensate for padding */ className="-mt-2"
                        onClick={onInviteMembers}
                    >
                        Invite Members
                    </Button>
                </div>
            )}

            <Table data-testid="members-table">
                <TableHeader className="[&_th]:border-b [&_th]:border-border-light [&_th]:bg-transparent">
                    <TableRow className="h-8 text-base">
                        <TableCell className="pl-3">Name</TableCell>
                        <TableCell className="pl-3">Date joined</TableCell>
                        <TableCell className="pl-3">Role</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHeader>
                <TableBody className="border-t border-border-light text-base">
                    {data.members.map((member) => (
                        <MemberItem key={member.userId} user={user} membership={membership} member={member} />
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

type MemberItemProps = {
    user: PlainMessage<User>;
    membership: PlainMessage<AccountMembership>;
    member: PlainOrganizationMember;
};

const MemberItem: FC<MemberItemProps> = ({ user, membership, member }: MemberItemProps) => {
    const navigate = useNavigate();

    const leaveOrganization = useLeaveOrganization();
    const suspendUser = useSuspendUser();
    const setRole = useSetRole();

    const [showModal, setShowModal] = useState<"suspend" | "change-role" | "leave" | undefined>(undefined);

    const isModalShown = showModal !== undefined;

    const isMemberCurrentUser = user.id === member.userId;

    const isAdmin = membership.userRole == OrganizationRole.ADMIN;

    // TODO(gpl) display suspended/left differently?
    const inactive: boolean = member.status !== UserStatus.ACTIVE;
    const memberIsSuspeded = member.status === UserStatus.SUSPENDED;

    const handleCopyId = useCallback(async () => {
        await navigator.clipboard.writeText(member.userId);
        toast({
            title: `Member ID copied to clipboard`,
            description: member.userId,
        });
    }, [member.userId]);

    const memberSince = useMemo(
        () => new Timestamp(member.memberSince).toDate().toLocaleDateString(),
        [member.memberSince],
    );

    const handleViewEnvironments = useCallback(() => {
        navigate(`/settings/environments?creator=${member.userId}`);
    }, [navigate, member.userId]);

    const handleUnsuspendUser = useCallback(async () => {
        await suspendUser.mutateAsync(
            { userId: member.userId, suspended: false },
            {
                onError: (e) => {
                    toast({ title: "Failed to unsuspend user", description: formatError(e) });
                },
            },
        );
    }, [member.userId, suspendUser]);

    return (
        <>
            <TableRow className="h-16 border-border-light">
                <TableCell className="max-w-xs">
                    <div className="inline-flex grow items-center space-x-3">
                        <img referrerPolicy="no-referrer" src={member.avatarUrl} className="h-8 w-8 rounded-full" />
                        <div className="flex flex-col">
                            <Text className={`text-base font-bold ${inactive && "line-through"}`}>
                                {member.fullName}
                            </Text>
                            <Text className={`text-base text-content-secondary`}>{member.email}</Text>
                        </div>
                    </div>
                </TableCell>
                <TableCell className="w-40">
                    <Text className={`text-base`}>{memberSince}</Text>
                </TableCell>
                <TableCell className="w-40">
                    <Text className={`text-base`}>{roleName(member.role)}</Text>
                </TableCell>
                <TableCell className="w-8">
                    <div className="flex flex-row items-center">
                        <DropdownActions triggerTestId={`user-actions-dropdown-trigger-${member.userId}`}>
                            {isAdmin && (
                                <DropdownMenuItem disabled={isModalShown} onClick={handleViewEnvironments}>
                                    View Environments
                                </DropdownMenuItem>
                            )}
                            <DropdownMenuItem onClick={handleCopyId}>Copy ID</DropdownMenuItem>
                            {isAdmin && (
                                <DropdownMenuItem disabled={isModalShown} onClick={() => setShowModal("change-role")}>
                                    Change Role
                                </DropdownMenuItem>
                            )}
                            {isMemberCurrentUser && (
                                <DropdownMenuItem disabled={isModalShown} onClick={() => setShowModal("leave")}>
                                    Leave
                                </DropdownMenuItem>
                            )}
                            {isAdmin && !memberIsSuspeded && !isMemberCurrentUser && (
                                <DropdownMenuItem
                                    disabled={isModalShown}
                                    onClick={() => setShowModal("suspend")}
                                    className="text-red-500"
                                    data-testid="user-action-suspend"
                                >
                                    Suspend
                                </DropdownMenuItem>
                            )}
                            {isAdmin && memberIsSuspeded && !isMemberCurrentUser && (
                                <DropdownMenuItem
                                    disabled={isModalShown}
                                    onClick={handleUnsuspendUser}
                                    data-testid="user-action-unsuspend"
                                >
                                    Unsuspend
                                </DropdownMenuItem>
                            )}
                        </DropdownActions>
                    </div>
                </TableCell>
                {showModal === "change-role" && (
                    <ChangeRoleModal
                        member={member}
                        onContinue={(role) => {
                            setShowModal(undefined);
                            setRole.mutate(
                                { userID: member.userId, role: role },
                                {
                                    onSuccess: () => {
                                        toast({
                                            title: `Changed role for user ${member.fullName} to ${roleName(role)}`,
                                        });
                                    },
                                    onError: (e) => {
                                        toast({ title: `Failed to change role`, description: formatError(e) });
                                    },
                                },
                            );
                        }}
                        onClose={() => {
                            setShowModal(undefined);
                        }}
                    />
                )}
                {showModal === "suspend" && (
                    <SuspendMemberModal
                        member={member}
                        onContinue={() => {
                            setShowModal(undefined);
                            suspendUser.mutate(
                                { userId: member.userId, suspended: true },
                                {
                                    onSuccess: () => {
                                        toast({ title: `User suspended` });
                                    },
                                    onError: (e) => {
                                        toast({ title: `Failed to suspend user`, description: formatError(e) });
                                    },
                                },
                            );
                        }}
                        onClose={() => {
                            setShowModal(undefined);
                        }}
                    />
                )}
                {showModal === "leave" && (
                    <LeaveOrganizationModal
                        onContinue={() => {
                            setShowModal(undefined);
                            leaveOrganization.mutate(
                                { userId: member.userId },
                                {
                                    onSuccess: () => {
                                        // TODO(at) check if this really works as intended
                                        navigate("/", { replace: true });
                                        toast({ title: `You've left the organization` });
                                    },
                                    onError: (e) => {
                                        toast({
                                            title: `Failed to leave the organization`,
                                            description: formatError(e),
                                        });
                                    },
                                },
                            );
                        }}
                        onClose={() => {
                            setShowModal(undefined);
                        }}
                    />
                )}
            </TableRow>
        </>
    );
};

const SuspendMemberModal: FC<{
    member: PlainOrganizationMember;
    onContinue: () => void;
    onClose: () => void;
}> = (p) => {
    return (
        <Dialog open onOpenChange={p.onClose}>
            <DialogContent className="max-w-lg">
                <DialogHeader>
                    <DialogTitle>Suspend member</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <DialogBody>
                    <div className="flex flex-col gap-4">
                        <Text>Are you sure you want to suspend this member from the organization?</Text>
                        <MemberCard member={p.member} />
                    </div>
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={p.onClose}>
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        type="submit"
                        autoFocus={true}
                        variant="destructive"
                        onClick={(e) => {
                            e.preventDefault();
                            p.onContinue();
                        }}
                    >
                        Yes, Suspend
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

const MemberCard: FC<{ member: PlainOrganizationMember } & PropsWithClassName> = (p) => {
    return (
        <Card
            className={cn(
                "inline-flex w-full items-center justify-start gap-2 rounded-xl border border-border-base bg-surface-glass px-4 py-2.5 pl-3",
                p.className,
            )}
        >
            <img referrerPolicy="no-referrer" src={p.member.avatarUrl} className="h-8 w-8 rounded-full" />
            <div className="inline-flex shrink grow basis-0 flex-col items-start justify-center">
                <Text className="text-base font-bold">{p.member.fullName}</Text>
                <Text className="text-base text-content-secondary">{p.member.email}</Text>
            </div>
        </Card>
    );
};

const ChangeRoleModal: FC<{
    member: PlainOrganizationMember;
    onContinue: (role: OrganizationRole) => void;
    onClose: () => void;
}> = (p) => {
    const [selectedRole, setSelectedRole] = useState(roleToString(p.member.role));

    return (
        <Dialog open onOpenChange={p.onClose}>
            <DialogContent className="max-w-lg">
                <DialogHeader>
                    <DialogTitle>Change role</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <DialogBody>
                    <MemberCard className="mb-4" member={p.member} />

                    <RadioGroup
                        value={selectedRole}
                        onValueChange={(val) => {
                            setSelectedRole(roleToString(roleFromString(val)));
                        }}
                    >
                        <div className="flex items-start justify-start gap-2 pt-1">
                            <RadioGroupItem
                                className="relative left-0 top-1 h-4 w-4"
                                value={roleToString(OrganizationRole.ADMIN)}
                                id="admin"
                            />
                            <label htmlFor="admin" className="cursor-pointer">
                                <Text className="font-bold">Admin</Text>
                                <Text>
                                    Admins can change organization settings, remove people, and manager secrets and
                                    templates
                                </Text>
                            </label>
                        </div>

                        <div className="flex items-start justify-start gap-2 pt-1">
                            <RadioGroupItem
                                className="relative left-0 top-1 h-4 w-4"
                                value={roleToString(OrganizationRole.MEMBER)}
                                id="member"
                            />
                            <label htmlFor="member" className="cursor-pointer">
                                <Text className="font-bold">Member</Text>
                                <Text>Members are standard Gitpod users</Text>
                            </label>
                        </div>
                    </RadioGroup>
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={p.onClose}>
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        type="submit"
                        autoFocus={true}
                        variant="destructive"
                        onClick={(e) => {
                            e.preventDefault();
                            p.onContinue(roleFromString(selectedRole));
                        }}
                    >
                        Continue
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

const LeaveOrganizationModal: FC<{
    onContinue: () => void;
    onClose: () => void;
}> = (p) => {
    return (
        <Dialog open onOpenChange={p.onClose}>
            <DialogContent className="max-w-lg">
                <DialogHeader>
                    <DialogTitle>Leave organization</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <DialogDescription>
                    Are you sure you want to leave this organization? You will have to be re-invited to come back.
                </DialogDescription>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={p.onClose}>
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        type="submit"
                        autoFocus={true}
                        variant="destructive"
                        onClick={(e) => {
                            e.preventDefault();
                            p.onContinue();
                        }}
                    >
                        Yes, Leave
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

function roleToString(role: OrganizationRole): "admin" | "member" | "unspecified" {
    switch (role) {
        case OrganizationRole.ADMIN:
            return "admin";
        case OrganizationRole.MEMBER:
            return "member";
        case OrganizationRole.UNSPECIFIED:
            return "unspecified";
    }
}

function roleFromString(role: string): OrganizationRole {
    switch (role) {
        case "admin":
            return OrganizationRole.ADMIN;
        case "member":
            return OrganizationRole.MEMBER;
        default:
            return OrganizationRole.UNSPECIFIED;
    }
}
