import { useState, type FC } from "react";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { useListSecrets } from "@/queries/secret-queries";
import { SkeletonBlock } from "@/components/podkit/loading/Skeleton";
import { Button } from "@/components/flexkit/Button";
import { ErrorMessage } from "@/components/ErrorMessage";
import { SecretsTable } from "@/components/projects/SecretsTable";
import { NewSecretModal } from "@/components/projects/NewSecretModal";

export const ProjectSecrets: FC<{ projectId: string }> = ({ projectId }) => {
    const { data: secrets, isPending, error } = useListSecrets(projectId);

    const [showAddSecretModal, setShowAddSecretModal] = useState(false);

    return (
        <div className="flex flex-col gap-2">
            <div className="flex justify-between">
                <div className="flex flex-col">
                    <Heading2 className="text-xl font-bold text-content-primary">Secrets</Heading2>
                    <Text className="text-base text-content-secondary">
                        Secrets are encrypted environment variables that keep sensitive data like API keys and passwords
                        hidden from your codebase.
                    </Text>
                </div>
                <Button
                    variant="secondary"
                    data-track-label="true"
                    onClick={() => setShowAddSecretModal(true)}
                    data-testid="add-secret-modal-trigger"
                >
                    Add secret
                </Button>
            </div>

            <SkeletonBlock ready={!isPending} failed={!!error}>
                <ErrorMessage error={error} />
                <SecretsTable secrets={secrets?.secrets || []} />
            </SkeletonBlock>

            {showAddSecretModal && (
                <NewSecretModal projectId={projectId} onClose={() => setShowAddSecretModal(false)} />
            )}
        </div>
    );
};
