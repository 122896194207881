import { useDocumentTitle } from "@/hooks/use-document-title";
import { ProjectHeader } from "@/routes/projects/details/ProjectHeader";
import { ProjectSecrets } from "@/routes/projects/details/ProjectSecrets";
import type { FC } from "react";
import { useParams } from "react-router-dom";

export const ProjectDetailsPage: FC = () => {
    const { projectId } = useParams();
    useDocumentTitle("Project - Details");

    if (!projectId) {
        return;
    }

    return (
        <div className="flex flex-col gap-6" data-testid="project-details-page">
            <ProjectHeader projectId={projectId} />
            <ProjectSecrets projectId={projectId} />
        </div>
    );
};
