import { useCallback, useEffect, useState, type FC } from "react";
import { Heading1 } from "@/components/podkit/typography/Headings";
import { Input } from "@/components/podkit/forms/Input";
import { PlusIcon, Search } from "lucide-react";
import { ProjectsList } from "@/routes/projects/ProjectsList";
import { CreateProjectModal } from "@/routes/projects/CreateProjectModal";
import { Text } from "@/components/podkit/typography/Text";
import { useListProjects, useListProjectStates } from "@/queries/project-queries";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { OrganizationRole } from "gitpod-next-api/gitpod/v1/organization_pb";
import { cn } from "@/components/podkit/lib/cn";
import { ProjectsFilterType } from "@/routes/projects/details/ProjectConstants";
import { useLocation, useNavigate } from "react-router-dom";
import { useMembership } from "@/hooks/use-membership";
import { CreateEnvironmentModal } from "@/routes/environments/create/CreateEnvironment";
import { IconDot } from "@/assets/icons/geist/IconDot";
import { Button } from "@/components/flexkit/Button";
import { IconPlus } from "@/assets/icons/geist/IconPlus";

export const ProjectsPage: FC = () => {
    useDocumentTitle("Projects");

    const location = useLocation();
    const navigate = useNavigate();
    const selectProjectId = (location.search && new URLSearchParams(location.search).get("project")) ?? undefined;

    const { data: projectsData, isPending: isPendingProjects } = useListProjects();
    const { membership } = useMembership();

    const [projectsFilterType, setProjectsFilterType] = useState(ProjectsFilterType.Active);
    const [searchValue, setSearchValue] = useState<string>();
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
    const [showNewEnvModal, setShowNewEnvModal] = useState(false);

    const handleCreateProject = useCallback(() => {
        setShowCreateProjectModal(true);
    }, [setShowCreateProjectModal]);

    const handleCreateEnvironment = useCallback(() => {
        setShowNewEnvModal(true);
    }, [setShowNewEnvModal]);

    const { data: projectsStates, isLoading: isLoadingProjectsStates } = useListProjectStates(
        projectsData?.projects.map((p) => p.id),
    );

    useEffect(() => {
        if (location.state?.showCreateModal) {
            setShowNewEnvModal(true);
            navigate({ pathname: location.pathname, hash: location.hash }, { replace: true });
        }
    }, [location, navigate]);

    if (isPendingProjects) {
        return <LoadingState />;
    }

    const isEmpty = projectsData?.projects.length === 0;
    const isAdmin = membership?.userRole === OrganizationRole.ADMIN;

    const notSharedNotification =
        !isLoadingProjectsStates &&
        projectsData?.projects.some(
            (p) =>
                p.metadata?.creator?.id === membership?.userId &&
                projectsStates?.some((ps) => ps.projectId === p.id && !ps.shared),
        );

    return (
        <>
            {isEmpty ? (
                <div className="flex h-full w-full flex-col items-center justify-center gap-2">
                    <Heading1>No projects</Heading1>
                    {isAdmin ? (
                        <Text className="w-[480px] pb-2 text-center">
                            Projects are pre-configured templates that provide one-click environments. First, create and
                            configure an environment, then share it as a project for your team.
                        </Text>
                    ) : (
                        <>
                            <Text className="w-[480px] text-center">
                                Projects are pre-configured templates that provide one-click environments.
                            </Text>
                            <Text className="w-[480px] pb-2 text-center text-xs">
                                Only admins can create projects for your organization. You can still create an
                                environment.
                            </Text>
                        </>
                    )}
                    <Button type="button" variant="primary" onClick={handleCreateEnvironment} data-track-label="true">
                        <PlusIcon className="inline-block h-4 w-4" />
                        Create an Environment
                    </Button>
                </div>
            ) : (
                <div className="flex h-full w-full flex-col gap-4 p-2">
                    <div className="flex items-center">
                        <Heading1 className="flex grow flex-row align-middle text-content-primary">Projects</Heading1>
                        {isAdmin && (
                            <Button
                                LeadingIcon={IconPlus}
                                variant="primary"
                                onClick={handleCreateProject}
                                data-track-label="true"
                            >
                                New Project
                            </Button>
                        )}
                    </div>
                    <div className="flex items-center gap-1.5">
                        <ProjectsFilterTabList
                            filterType={projectsFilterType}
                            onFilterTypeChange={(v) => setProjectsFilterType(v)}
                            disabled={!!selectProjectId}
                            notSharedNotification={notSharedNotification}
                        />
                        <div className="relative flex grow">
                            <Search className="absolute left-[12px] top-[11px] z-10 text-content-tertiary" size={14} />
                            <Input
                                placeholder="Search projects"
                                className="max-w-none pl-8"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value.trimStart().replace(/\s+/g, " "))}
                                disabled={!!selectProjectId}
                            />
                        </div>
                    </div>
                    <div className="min-w-[500px] overflow-y-auto">
                        <ProjectsList
                            showRecentlyUsedOnly={true}
                            canEdit={true}
                            filter={projectsFilterType}
                            search={searchValue}
                            selectProjectId={selectProjectId}
                        />
                    </div>
                </div>
            )}
            {showCreateProjectModal && <CreateProjectModal onClose={() => setShowCreateProjectModal(false)} />}
            {showNewEnvModal && <CreateEnvironmentModal onClose={() => setShowNewEnvModal(false)} />}
        </>
    );
};

const ProjectsFilterTabList: FC<{
    filterType: ProjectsFilterType;
    disabled?: boolean;
    onFilterTypeChange: (filterType: ProjectsFilterType) => void;
    notSharedNotification?: boolean;
}> = ({ filterType, disabled, onFilterTypeChange, notSharedNotification }) => {
    return (
        <ul className="flex items-center gap-1.5" role="tablist">
            <li role="tab" className="block">
                <a
                    className={cn(
                        "block h-9 cursor-pointer select-none text-nowrap rounded-lg bg-transparent px-4 py-2 text-base font-medium text-content-secondary",
                        filterType === ProjectsFilterType.Active
                            ? "text-content-03 bg-surface-03"
                            : "hover:bg-surface-02",
                        disabled && "pointer-events-none bg-surface-tertiary text-content-tertiary",
                    )}
                    onClick={() => onFilterTypeChange(ProjectsFilterType.Active)}
                >
                    Shared Projects
                </a>
            </li>
            <li role="tab" className="relative block">
                <a
                    className={cn(
                        "block h-9 cursor-pointer select-none text-nowrap rounded-lg bg-transparent px-4 py-2 text-base font-medium text-content-secondary",
                        filterType === ProjectsFilterType.NotShared
                            ? "text-content-03 bg-surface-03"
                            : "hover:bg-surface-02",
                        disabled && "pointer-events-none bg-surface-tertiary text-content-tertiary",
                    )}
                    onClick={() => onFilterTypeChange(ProjectsFilterType.NotShared)}
                >
                    Not Shared
                    {notSharedNotification && (
                        <span className="absolute -right-1 -top-1 flex h-5 w-5 items-center justify-center">
                            <IconDot size="sm" className="text-content-green" />
                        </span>
                    )}
                </a>
            </li>
            <li role="tab" className="block">
                <a
                    className={cn(
                        "block h-9 cursor-pointer select-none text-nowrap rounded-lg bg-transparent px-4 py-2 text-base font-medium text-content-secondary",
                        filterType === ProjectsFilterType.All ? "text-content-03 bg-surface-03" : "hover:bg-surface-02",
                        disabled && "pointer-events-none bg-surface-tertiary text-content-tertiary",
                    )}
                    onClick={() => onFilterTypeChange(ProjectsFilterType.All)}
                >
                    All Projects
                </a>
            </li>
        </ul>
    );
};
