import { OrgSwitcher } from "@/components/OrgSwitcher";
import { Button } from "@/components/flexkit/Button";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import { Dialog, DialogContent } from "@/components/podkit/modal/Modal";
import { X } from "lucide-react";
import { useEffect, type FC, type PropsWithChildren } from "react";

type Props = {
    onClose?: () => void;
    showOrgSwitcher: boolean;
    "data-testid"?: string;
};

export const CloseableFullPage: FC<Props & React.PropsWithChildren> = (p) => {
    return (
        <EmptyClosableFullPage onClose={p.onClose} data-testid={p["data-testid"]}>
            <div className="relative flex min-h-16 flex-row items-center justify-between overflow-auto p-3 px-4">
                {p.showOrgSwitcher && <OrgSwitcher />}
                {p.onClose && <EscButton onClose={p.onClose} />}
            </div>
            <div className="flex h-full overflow-y-auto">{p.children}</div>
        </EmptyClosableFullPage>
    );
};

export const EscButton: FC<{ onClose?: () => void }> = ({ onClose }) => {
    return (
        <Button
            variant="ghost"
            onClick={onClose}
            className="absolute right-4 top-4 rounded-sm p-0 opacity-70 transition-opacity hover:opacity-100 disabled:pointer-events-none"
        >
            <div className="flex flex-col items-center px-2">
                <X className="h-4 w-4 text-content-primary" />
                <span className="text-sm text-content-tertiary">esc</span>
            </div>
        </Button>
    );
};

export const EmptyClosableFullPage: FC<
    {
        onClose?: () => void;
        "data-testid"?: string;
    } & PropsWithChildren &
        PropsWithClassName
> = (p) => {
    const height = window.ipcRenderer ? "calc(100vh - 4rem)" : "calc(100vh - 3.5rem)";
    return (
        <ClosableWithEsc onClose={p.onClose} data-testid={p["data-testid"]}>
            <div
                className={cn(
                    "absolute left-0 top-0 mx-4 mb-4 mt-8 flex flex-col overflow-hidden rounded-xl border border-border-light bg-surface-secondary shadow-md shadow-border-light",
                    p.className,
                )}
                style={{ height: height, width: "calc(100vw - 2rem)" }}
            >
                {p.children}
            </div>
        </ClosableWithEsc>
    );
};

export const ClosableWithEsc: FC<
    {
        onClose?: () => void;
        "data-testid"?: string;
    } & PropsWithChildren &
        PropsWithClassName
> = (p) => {
    // capture escape key to close the modal
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Escape" && p.onClose && !e.defaultPrevented) {
                e.preventDefault();
                p.onClose();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [p]);

    return (
        <div data-testid={p["data-testid"]} className={p.className}>
            {p.children}
        </div>
    );
};

type ClosableFullPageModalProps = {
    onClose?: () => void;
    "data-testid"?: string;
} & PropsWithChildren &
    PropsWithClassName;

export const ClosableFullPageModal: FC<ClosableFullPageModalProps> = (p) => {
    // capture escape key to close the modal
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Escape" && p.onClose && !e.defaultPrevented) {
                e.stopPropagation();
                e.preventDefault();
                p.onClose();
            }
        };
        window.addEventListener("keydown", handleKeyDown, { capture: false });
        return () => window.removeEventListener("keydown", handleKeyDown, { capture: false });
    }, [p]);

    const onOpenChange = (open: boolean) => {
        if (!open) {
            p.onClose?.();
        }
    };

    return (
        <Dialog open onOpenChange={onOpenChange}>
            <DialogContent
                className={cn("flex max-h-[90%] max-w-[96%] p-0", p.className)}
                data-testid={p["data-testid"]}
            >
                {p.children}
            </DialogContent>
        </Dialog>
    );
};

export const JustifiedContentColumn: FC<PropsWithChildren> = (p) => {
    return (
        <div className="flex h-full w-full grow flex-col items-center px-8 py-4">
            <div className="flex w-full min-w-[50%] max-w-[100%] grow flex-col justify-center">{p.children}</div>
        </div>
    );
};
