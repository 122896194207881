export enum ProjectDetails {
    Settings = "Settings",
    DevelopmentContainer = "Development Container",
    Automations = "Automations",
    Sharing = "Sharing",
}

export enum DevcontainerConfig {
    Template = "template",
    FilePath = "filepath",
}

export enum AutomationConfig {
    Template = "template",
    FilePath = "filepath",
}

export enum ProjectsFilterType {
    All = "all",
    Active = "active",
    NotShared = "notShared",
}

export enum ProjectSharingType {
    OnlyMe = "only-me",
    AnyoneInOrg = "anyone-in-org",
}
