import { useCallback, useEffect, useId, useState, type FC, type FormEvent, useMemo } from "react";
import {
    useCreateProjectPolicy,
    useDeleteProjectPolicy,
    useUpdateProject,
    type PlainProject,
    type PlainProjectPolicy,
    type ProjectState,
} from "@/queries/project-queries";
import { ContextUrlInput } from "@/components/ContextUrlInput";
import { EnvironmentTypeSelect } from "@/components/EnvironmentTypeSelect";
import { Input } from "@/components/podkit/forms/Input";
import { Label } from "@/components/podkit/forms/Label";
import { useEnvironmentTypes, type EnvironmentTypeEntry } from "@/hooks/use-environment-types";
import {
    type ContextURLInitializer,
    type EnvironmentInitializer,
    type GitInitializer,
} from "gitpod-next-api/gitpod/v1/environment_pb";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { formatError } from "@/utils/errors";
import { cn } from "@/components/podkit/lib/cn";
import { DevcontainerConfig, ProjectDetails, ProjectSharingType } from "@/routes/projects/details/ProjectConstants";
import { RadioGroup, RadioGroupItem } from "@/components/podkit/forms/RadioListField";
import { InputField } from "@/components/podkit/forms/InputField";
import { ProjectRole } from "gitpod-next-api/gitpod/v1/project_pb";
import { DialogClose } from "@/components/podkit/modal/Modal";
import type { PlainMessage } from "@bufbuild/protobuf";
import { equals } from "@/utils/objects";
import { RunnerKind, RunnerPhase } from "gitpod-next-api/gitpod/v1/runner_pb";
import { Button } from "@/components/flexkit/Button";

// localEnvType is a "special" environment type for projects that represents local runners.
const localEnvType: EnvironmentTypeEntry = {
    clazz: {
        enabled: true,
        id: "local",
        description: "Local",
        displayName: "Gitpod Desktop",
        runnerId: "local",
        configuration: [],
    },
    runner: {
        status: {
            phase: RunnerPhase.ACTIVE,
            version: "",
            systemDetails: "",
            logUrl: "",
            additionalInfo: [],
            message: "",
            region: "",
        },
        runnerId: "local",
        name: "",
        kind: RunnerKind.LOCAL,
    },
};

export const ProjectDetailsForm: FC<{
    project: PlainProject;
    state: ProjectState;
    activeProjectDetails: ProjectDetails;
    onSuccess: () => void;
}> = ({ project, state, activeProjectDetails, onSuccess }) => {
    const [projectName, setProjectName] = useState<string>(project.metadata?.name || "");
    const inputNameID = useId();

    const [initializer, setInitializer] = useState<PlainMessage<EnvironmentInitializer> | undefined>(
        project.initializer,
    );

    const { envTypes: queriedEnvTypes, isLoading: isLoadingEnvTypes } = useEnvironmentTypes();
    const [selectedEnvironment, setSelectedEnvironment] = useState<EnvironmentTypeEntry>();

    const envTypes = useMemo(() => {
        return [localEnvType].concat(queriedEnvTypes.filter((e) => e.runner.kind !== RunnerKind.LOCAL));
    }, [queriedEnvTypes]);

    const [devcontainerConfig, setDevcontainerConfig] = useState<DevcontainerConfig>(DevcontainerConfig.Template);
    const [devcontainerFilePath, setDevcontainerFilePath] = useState<string>(
        project.devcontainerFilePath || ".devcontainer/devcontainer.json",
    );

    const [automationsFilePath, setAutomationsFilePath] = useState<string>(
        project.automationsFilePath || ".gitpod/automations.yaml",
    );

    const [sharingValue, setSharingValue] = useState<string>(
        state.shared ? ProjectSharingType.AnyoneInOrg : ProjectSharingType.OnlyMe,
    );

    const { toast } = useToast();

    const updateProject = useUpdateProject();
    const createProjectPolicy = useCreateProjectPolicy();
    const deleteProjectPolicy = useDeleteProjectPolicy();

    const runnerID = selectedEnvironment?.runner.runnerId;
    const classID = selectedEnvironment?.clazz.id;
    const formChanged =
        projectName !== project.metadata?.name ||
        !equals(project.initializer, initializer) ||
        (!!classID && classID !== project.environmentClass?.environmentClass.value) ||
        devcontainerFilePath !== project.devcontainerFilePath ||
        automationsFilePath !== project.automationsFilePath ||
        (sharingValue === (ProjectSharingType.AnyoneInOrg as string) && !state.shared) ||
        (sharingValue === (ProjectSharingType.OnlyMe as string) && state.shared);
    const handleSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (!projectName || !runnerID || !classID || !initializer) {
                console.log(
                    `Invalid project Name${projectName} or runnerId=${runnerID} or classID=${classID} or initializer`,
                );
                return;
            }

            const updatePromises: Promise<PlainProject | PlainProjectPolicy | void>[] = [
                updateProject.mutateAsync({
                    projectId: project.id,
                    name: projectName !== project.metadata?.name ? projectName : undefined,
                    initializer: !equals(project.initializer, initializer) ? initializer : undefined,
                    classID: classID === localEnvType.clazz.id ? "" : classID,
                    devcontainerFilePath:
                        devcontainerFilePath !== project.devcontainerFilePath ? devcontainerFilePath : undefined,
                    automationsFilePath:
                        automationsFilePath !== project.automationsFilePath ? automationsFilePath : undefined,
                }),
            ];
            if (sharingValue === (ProjectSharingType.AnyoneInOrg as string) && !state.shared) {
                updatePromises.push(
                    createProjectPolicy.mutateAsync({
                        projectId: project.id,
                        groupId: state.orgMembersGroupId,
                        role: ProjectRole.USER,
                    }),
                );
            } else if (sharingValue === (ProjectSharingType.OnlyMe as string) && state.shared) {
                updatePromises.push(
                    deleteProjectPolicy.mutateAsync({ projectId: project.id, groupId: state.orgMembersGroupId }),
                );
            }

            const resultArr = await Promise.allSettled(updatePromises);
            if (resultArr.every((r) => r.status === "fulfilled")) {
                toast({
                    title: "Project updated",
                });
                onSuccess();
            } else {
                const firstErr = resultArr.find((r) => r.status === "rejected");
                toast({
                    title: "Failed to update project",
                    description: formatError(firstErr?.reason),
                });
            }
        },
        [
            projectName,
            runnerID,
            classID,
            updateProject,
            project,
            devcontainerFilePath,
            automationsFilePath,
            sharingValue,
            state,
            createProjectPolicy,
            deleteProjectPolicy,
            toast,
            onSuccess,
            initializer,
        ],
    );

    // Set contextURL and environment type from project data
    useEffect(() => {
        if (!selectedEnvironment && envTypes.length) {
            let projectEnvType: EnvironmentTypeEntry | undefined;
            if (project?.environmentClass?.environmentClass.case === "localRunner") {
                projectEnvType = localEnvType;
            } else {
                projectEnvType = envTypes.find((e) => e.clazz.id === project?.environmentClass?.environmentClass.value);
            }
            if (projectEnvType) {
                setSelectedEnvironment(projectEnvType);
            }
        }
    }, [project, envTypes, selectedEnvironment]);

    const updateButtonDisabled = !selectedEnvironment || !projectName || !initializer || !formChanged;

    const metadataFields = (
        <div className={cn("flex flex-col gap-4", activeProjectDetails !== ProjectDetails.Settings && "hidden")}>
            <div className="flex flex-col gap-2">
                <h1 className="text-lg font-bold text-content-primary">Settings</h1>
            </div>
            <div
                className="flex flex-col gap-4"
                id="update-environment-form"
                data-testid={isLoadingEnvTypes ? "loading" : "update-environment-form"}
            >
                <div className="space-y-1">
                    <InputField label="Display name" id={inputNameID}>
                        <Input
                            id={inputNameID}
                            type="text"
                            value={projectName || ""}
                            placeholder="Ex: My Project"
                            onChange={(e) => setProjectName(e.target.value.trimStart().replace(/\s+/g, " "))}
                        />
                    </InputField>
                </div>
                <ProjectInitializerFields
                    initializer={project?.initializer}
                    onUpdate={(v) => {
                        setInitializer(v);
                    }}
                    onError={() => setInitializer(undefined)}
                />
                <EnvironmentTypeSelect
                    label="Default environment"
                    value={selectedEnvironment}
                    onChange={(v) => setSelectedEnvironment(v)}
                    name="environment-type"
                    loading={isLoadingEnvTypes}
                    disabled={false}
                    envTypes={envTypes}
                />
            </div>
        </div>
    );
    const devcontainerFields = (
        <RadioGroup
            value={devcontainerConfig}
            onValueChange={(v) => setDevcontainerConfig(v as DevcontainerConfig)}
            className={cn(
                "flex flex-col gap-4",
                activeProjectDetails !== ProjectDetails.DevelopmentContainer && "hidden",
            )}
        >
            <div className="flex flex-col gap-2">
                <h1 className="text-lg font-bold text-content-primary">Development Container</h1>
                <p className="text-base text-content-secondary">
                    A devcontainer is a Docker container that is configured to provide a standardized development
                    environment for a project, allowing everyone to work consistently across different machines. Learn
                    more.
                </p>
            </div>
            <Label
                className={cn(
                    "flex items-start gap-2 rounded-lg border border-border-light p-2 font-normal",
                    "bg-surface-secondary",
                )}
            >
                <div className="flex grow flex-col gap-2">
                    <span className="text-base font-bold">File path</span>
                    <Input
                        className="max-w-none"
                        value={devcontainerFilePath}
                        onChange={(e) => setDevcontainerFilePath(e.target.value)}
                        disabled={false}
                    />
                </div>
            </Label>
        </RadioGroup>
    );
    const automationFields = (
        <div className={cn("flex flex-col gap-4", activeProjectDetails !== ProjectDetails.Automations && "hidden")}>
            <div className="flex flex-col gap-2">
                <h1 className="text-lg font-bold text-content-primary">Automations</h1>
                <p className="text-base text-content-secondary">
                    Gitpod allows you to automate services and task for your environment. These Automations are defined
                    in a file that is checked into you repo. Learn more.
                </p>
            </div>
            <Label
                className={cn(
                    "flex items-start gap-2 rounded-lg border border-border-light p-2 font-normal",
                    "bg-surface-secondary",
                )}
            >
                <div className="flex grow flex-col gap-2">
                    <span className="text-base font-bold">File path</span>
                    <Input
                        className="max-w-none"
                        value={automationsFilePath}
                        onChange={(e) => setAutomationsFilePath(e.target.value)}
                        disabled={false}
                    />
                </div>
            </Label>
        </div>
    );
    const sharingFields = (
        <RadioGroup
            value={sharingValue}
            onValueChange={setSharingValue}
            className={cn("flex flex-col gap-2", activeProjectDetails !== ProjectDetails.Sharing && "hidden")}
        >
            <div className="flex flex-col gap-4">
                <h1 className="text-xl font-medium text-content-primary">Sharing</h1>
                <p className="text-base text-content-secondary">Who can use this project?</p>
            </div>
            <Label className="flex items-start space-x-2 font-normal">
                <RadioGroupItem value={ProjectSharingType.OnlyMe} />
                <div className="flex flex-col">
                    <span className="text-base">Only me</span>
                </div>
            </Label>
            <Label className="flex items-start space-x-2 font-normal">
                <RadioGroupItem value={ProjectSharingType.AnyoneInOrg} />
                <div className="flex flex-col">
                    <span className="text-base">Everyone in the organization</span>
                </div>
            </Label>
        </RadioGroup>
    );

    return (
        <form onSubmit={handleSubmit} className="flex h-full w-full flex-col">
            <div className="grow pt-1">
                {metadataFields}
                {devcontainerFields}
                {automationFields}
                {sharingFields}
            </div>
            <div className="mt-4 flex justify-end gap-2">
                <DialogClose asChild>
                    <Button type="button" variant="secondary" data-track-label="true">
                        Cancel
                    </Button>
                </DialogClose>
                <Button
                    type="submit"
                    variant="primary"
                    loading={false}
                    disabled={updateButtonDisabled}
                    data-track-label="true"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

type InitializerProps = {
    initializer?: PlainMessage<EnvironmentInitializer>;
    onUpdate: (initializer?: PlainMessage<EnvironmentInitializer>) => void;
    onError: (error: string) => void;
};

const ProjectInitializerFields: React.FC<InitializerProps> = ({ initializer, onUpdate, onError }) => {
    if (initializer?.specs[0].spec.case === "contextUrl") {
        return (
            <ContextURLInputField
                initializer={initializer?.specs[0].spec.value}
                onUpdate={(updatedValue) =>
                    onUpdate({ specs: [{ spec: { value: updatedValue, case: "contextUrl" } }] })
                }
                onError={onError}
            />
        );
    }
    if (initializer?.specs[0].spec.case === "git") {
        return (
            <GitInitializerInput
                initializer={initializer?.specs[0].spec.value}
                onUpdate={(updatedValue) => onUpdate({ specs: [{ spec: { value: updatedValue, case: "git" } }] })}
                onError={onError}
            />
        );
    }
    return <></>;
};

const ContextURLInputField: React.FC<{
    initializer: PlainMessage<ContextURLInitializer>;
    onUpdate: (initializer: PlainMessage<ContextURLInitializer>) => void;
    onError: (error: string) => void;
}> = ({ initializer, onUpdate, onError }) => {
    const [contextURL, setContextURL] = useState(initializer.url);

    const handleChange = useCallback(
        (newURL: string) => {
            setContextURL(newURL);

            if (!newURL) {
                onError("Repository URL is required");
                return;
            }
            try {
                new URL(newURL);
            } catch {
                onError("Invalid Repository URL");
                return;
            }

            onUpdate({ url: newURL });
        },
        [onUpdate, onError],
    );

    return <ContextUrlInput label="Context URL" onChange={(v) => handleChange(v)} value={contextURL} />;
};

const GitInitializerInput: React.FC<{
    initializer: PlainMessage<GitInitializer>;
    onUpdate: (initializer: PlainMessage<GitInitializer>) => void;
    onError: (error: string) => void;
}> = ({ initializer, onUpdate, onError }) => {
    const inputRepoUrlID = useId();
    const inputBranchID = useId();

    const [branch, setBranch] = useState<string>(initializer.cloneTarget);
    const [repoURL, setRepoURL] = useState<string>(initializer.remoteUri);

    const handleChange = useCallback(
        (newRepoURL: string, newBranch: string) => {
            setRepoURL(newRepoURL);
            setBranch(newBranch);

            if (!newRepoURL) {
                onError("Repository URL is required");
                return;
            }
            if (!newBranch) {
                onError("Branch is required");
                return;
            }
            try {
                new URL(newRepoURL);
            } catch {
                onError("Invalid Repository URL");
                return;
            }

            const value = {
                ...initializer,
                cloneTarget: newBranch,
                remoteUri: newRepoURL,
            };
            onUpdate(value);
        },
        [initializer, onUpdate, onError],
    );

    return (
        <div className="flex flex-col gap-2">
            <InputField label="Repository Clone URL" id={inputRepoUrlID}>
                <Input
                    id={inputRepoUrlID}
                    type="text"
                    value={repoURL}
                    onChange={(e) => handleChange(e.target.value.trimStart().replace(/\s+/g, " "), branch)}
                />
            </InputField>
            <InputField label="Branch" id={inputBranchID}>
                <Input
                    id={inputBranchID}
                    type="text"
                    value={branch}
                    onChange={(e) => handleChange(repoURL, e.target.value.trimStart().replace(/\s+/g, " "))}
                />
            </InputField>
        </div>
    );
};
