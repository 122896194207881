import { useId, type FC, useState, useMemo, useCallback } from "react";
import { Card } from "@/components/podkit/Card";
import { Button } from "@/components/flexkit/Button";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogBody,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from "@/components/podkit/modal/Modal";
import { Text } from "@/components/podkit/typography/Text";
import { type PlainOrganization, useDeleteOrganization } from "@/queries/organization-queries";
import { OrgIcon } from "@/components/OrgIcon";
import { Heading4, Subheading } from "@/components/podkit/typography/Headings";
import { Label } from "@/components/podkit/forms/Label";
import { Input } from "@/components/podkit/forms/Input";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { formatError } from "@/utils/errors";
import { useMembership } from "@/hooks/use-membership";

type DeleteOrganizationModalProps = {
    org: PlainOrganization;
    onContinue: () => void;
    onClose: () => void;
};

export const DeleteOrganizationModal: FC<DeleteOrganizationModalProps> = (p: DeleteOrganizationModalProps) => {
    const { toast } = useToast();
    const confirmationID = useId();
    const [confirmationValue, setConfirmationValue] = useState<string>("");

    const deleteOrganization = useDeleteOrganization();

    const onOpenChange = (open: boolean) => {
        if (!open) {
            p.onClose();
        }
    };

    const confirmed = useMemo(() => confirmationValue === p.org.name, [confirmationValue, p.org.name]);

    const handleDeleteOrganization = useCallback(() => {
        deleteOrganization.mutate(undefined, {
            onSuccess: () => {
                toast({ title: "Organization deleted" });
                p.onContinue();
            },
            onError: (err) => {
                toast({ title: "Couldn't delete organization", description: formatError(err) });
            },
        });
    }, [p, deleteOrganization, toast]);

    return (
        <Dialog open onOpenChange={onOpenChange}>
            <DialogContent className="max-w-lg">
                <DialogHeader>
                    <DialogTitle>Delete organization</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <DialogBody className="flex flex-col gap-4">
                    <OrganizationCard org={p.org} />
                    <Text>
                        Are you sure you want to permanently delete{" "}
                        <span className="text-lg font-bold text-content-primary">{p.org.name}</span>?
                    </Text>

                    <ol className="list-outside list-decimal pl-6">
                        <li className="pl-1">
                            All <b>environments</b> will be deleted and cannot be restored later.
                        </li>
                        <li className="pl-1">
                            All <b>members</b> will be lose access to this organization and environments.
                        </li>
                    </ol>

                    <Label htmlFor={confirmationID}>
                        Type <span className="font-bold text-content-primary">{p.org.name}</span> to confirm
                    </Label>
                    <Input
                        id={confirmationID}
                        type="text"
                        name="confirmation_value"
                        placeholder="Type to confirm"
                        value={confirmationValue}
                        onChange={(e) => setConfirmationValue(e.target.value)}
                    />
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={p.onClose}>
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        variant="destructive"
                        autoFocus={true}
                        disabled={!confirmed}
                        loading={deleteOrganization.isPending}
                        onClick={handleDeleteOrganization}
                    >
                        Yes, Delete Organization
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

const OrganizationCard: FC<{ org: PlainOrganization }> = (p) => {
    const cns = "inline-flex w-full items-center justify-start gap-2 border-0 rounded-xl p-2 bg-surface-secondary";
    const { membership } = useMembership();
    if (!membership) {
        return <Card className={cns} />;
    }
    return (
        <Card className={cns}>
            <OrgIcon orgName={p.org.name} />
            <div>
                <Heading4>{p.org.name}</Heading4>
                <Subheading>
                    {membership.organizationMemberCount} member
                    <span>{membership.organizationMemberCount !== 1 && "s"}</span>
                </Subheading>
            </div>
        </Card>
    );
};
