import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconBranch: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.75 1.75V1H3.25V1.75V9.09451C1.95608 9.42754 1 10.6021 1 12C1 13.6569 2.34315 15 4 15C5.42051 15 6.61042 14.0127 6.921 12.6869C8.37102 12.4872 9.7262 11.8197 10.773 10.773C11.8197 9.7262 12.4872 8.37102 12.6869 6.921C14.0127 6.61042 15 5.42051 15 4C15 2.34315 13.6569 1 12 1C10.3431 1 9 2.34315 9 4C9 5.37069 9.91924 6.52667 11.1749 6.8851C10.9929 7.94904 10.4857 8.9389 9.71231 9.71231C8.9389 10.4857 7.94904 10.9929 6.8851 11.1749C6.59439 10.1565 5.77903 9.35937 4.75 9.09451V1.75ZM13.5 4C13.5 4.82843 12.8284 5.5 12 5.5C11.1716 5.5 10.5 4.82843 10.5 4C10.5 3.17157 11.1716 2.5 12 2.5C12.8284 2.5 13.5 3.17157 13.5 4ZM4 13.5C4.82843 13.5 5.5 12.8284 5.5 12C5.5 11.1716 4.82843 10.5 4 10.5C3.17157 10.5 2.5 11.1716 2.5 12C2.5 12.8284 3.17157 13.5 4 13.5Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.75 3.75V3H5.25V3.75V11.0945C3.95608 11.4275 3 12.6021 3 14C3 15.6569 4.34315 17 6 17C7.42051 17 8.61042 16.0127 8.921 14.6869C10.371 14.4872 11.7262 13.8197 12.773 12.773C13.8197 11.7262 14.4872 10.371 14.6869 8.921C16.0127 8.61042 17 7.42051 17 6C17 4.34315 15.6569 3 14 3C12.3431 3 11 4.34315 11 6C11 7.37069 11.9192 8.52667 13.1749 8.8851C12.9929 9.94904 12.4857 10.9389 11.7123 11.7123C10.9389 12.4857 9.94904 12.9929 8.8851 13.1749C8.59439 12.1565 7.77903 11.3594 6.75 11.0945V3.75ZM15.5 6C15.5 6.82843 14.8284 7.5 14 7.5C13.1716 7.5 12.5 6.82843 12.5 6C12.5 5.17157 13.1716 4.5 14 4.5C14.8284 4.5 15.5 5.17157 15.5 6ZM6 15.5C6.82843 15.5 7.5 14.8284 7.5 14C7.5 13.1716 6.82843 12.5 6 12.5C5.17157 12.5 4.5 13.1716 4.5 14C4.5 14.8284 5.17157 15.5 6 15.5Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.28571 4.85714V4H6.57143V4.85714V13.2509C5.09266 13.6315 4 14.9738 4 16.5714C4 18.465 5.53503 20 7.42857 20C9.05201 20 10.4119 18.8717 10.7669 17.3565C12.424 17.1282 13.9728 16.3654 15.1691 15.1691C16.3654 13.9728 17.1282 12.424 17.3565 10.7669C18.8717 10.4119 20 9.05201 20 7.42857C20 5.53503 18.465 4 16.5714 4C14.6778 4 13.1429 5.53503 13.1429 7.42857C13.1429 8.99507 14.1934 10.3162 15.6285 10.7258C15.4205 11.9418 14.8408 13.073 13.9569 13.9569C13.073 14.8408 11.9418 15.4205 10.7258 15.6285C10.3936 14.4646 9.46175 13.5536 8.28571 13.2509V4.85714ZM18.2857 7.42857C18.2857 8.37535 17.5182 9.14286 16.5714 9.14286C15.6247 9.14286 14.8571 8.37535 14.8571 7.42857C14.8571 6.48179 15.6247 5.71429 16.5714 5.71429C17.5182 5.71429 18.2857 6.48179 18.2857 7.42857ZM7.42857 18.2857C8.37535 18.2857 9.14286 17.5182 9.14286 16.5714C9.14286 15.6247 8.37535 14.8571 7.42857 14.8571C6.48179 14.8571 5.71429 15.6247 5.71429 16.5714C5.71429 17.5182 6.48179 18.2857 7.42857 18.2857Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
