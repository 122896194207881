import { useCallback, useState, type FC } from "react";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { Button } from "@/components/flexkit/Button";
import { NewSecretForm, type NewSecretFormOnSubmitInput } from "@/components/projects/NewSecretForm";
import { useCreateSecret } from "@/queries/secret-queries";
import { ErrorMessage } from "@/components/ErrorMessage";
import { useToast } from "@/components/podkit/toasts/use-toast";

export const NewSecretModal: FC<{ projectId: string; onClose: () => void }> = ({ projectId, onClose }) => {
    const { toast } = useToast();
    const createSecret = useCreateSecret();

    const handleOpenChange = useCallback(
        (nextOpen: boolean) => {
            if (!nextOpen) {
                onClose();
            }
        },
        [onClose],
    );

    const onSubmit = useCallback(
        async ({ secretName, secretValuePlaintext }: NewSecretFormOnSubmitInput) => {
            try {
                await createSecret.mutateAsync({
                    secretName: secretName,
                    plaintextValue: secretValuePlaintext,
                    projectId: projectId,
                });
                onClose();
            } catch {
                toast({
                    title: "Failed to create secret",
                    description: "An error occurred while creating the secret",
                });
            }
        },
        [projectId, createSecret, onClose, toast],
    );

    const [disabled, setDisabled] = useState<boolean>(true);

    return (
        <Dialog open onOpenChange={handleOpenChange}>
            <DialogContent className="max-w-[600px]" data-testid="new-secret-form-modal-content">
                <DialogHeader>
                    <DialogTitle>New secret</DialogTitle>
                    <DialogDescription>Add a new secret to this project</DialogDescription>
                </DialogHeader>

                <DialogBody className="overflow-x max-w-full">
                    <ErrorMessage
                        error={createSecret.error}
                        data-testid="error-message-secret-creation-failed"
                        className="text-center"
                    />
                    <NewSecretForm formName="new-secret-form" onSubmit={onSubmit} onDisabledChange={setDisabled} />
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        type="submit"
                        variant="primary"
                        form="new-secret-form"
                        loading={createSecret.isPending}
                        disabled={createSecret.isPending || disabled}
                        data-testid="add-secret-submit-button"
                    >
                        Add Secret
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
