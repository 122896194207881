import { type FC, useCallback, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CreateOrganizationComponent } from "@/routes/create-organization/CreateOrganizationComponent";
import { type PlainOrganization, type PlainOrganizationMember } from "@/queries/organization-queries";
import { setPrincipal } from "@/principal";
import { ClosableWithEsc, JustifiedContentColumn } from "@/components/CloseableFullPage";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useGetAccount } from "@/queries/account-queries";
import { useLogout } from "@/queries/user-queries";
import { Button } from "@/components/flexkit/Button";
import { QuestionareTimeline } from "@/routes/onboarding/components/QuestionnaireTimeline";
import { OnboardingQuestionnaireId, useOnboardingQuestionnaire } from "@/hooks/use-onboarding-questionnaire";

export const CreateOrganizationPage: FC = () => {
    useDocumentTitle("Create Organization");

    const navigate = useNavigate();
    const location = useLocation();

    const { data: account } = useGetAccount();
    const onboardingQuestionnaire = useOnboardingQuestionnaire();
    const logout = useLogout();

    const onSuccess = useCallback(
        (_: PlainOrganization, member: PlainOrganizationMember) => {
            setPrincipal(member.userId);
            onboardingQuestionnaire.complete(OnboardingQuestionnaireId.CreateOrg);
            navigate("/onboarding/primary-interests", { replace: true });
        },
        [navigate, onboardingQuestionnaire],
    );

    const onClose = useMemo(() => {
        // We we're rendering this page coming from JoinOrganization then "Back"/<kbd>Escape</kbd> should navigate back.
        if (location?.state?.from == "join") {
            return () => navigate(-1);
        }

        // If this is rendered on direct navigation to `/create-organization` _and_ there is an Org membership
        // to continue with, "Back"/<kbd>Escape</kbd> should navigate to root.
        if ((account?.memberships?.length || 0) > 0) {
            return async () => {
                await logout.mutateAsync();
                navigate("/");
            };
        }

        // Otherwise, specifically in sign-up case, this is a blocking state "Back"/<kbd>Escape</kbd> should not be available.
        return undefined;
    }, [location?.state?.from, account, navigate, logout]);

    return (
        <ClosableWithEsc onClose={onClose} data-testid="create-organization-page" className="h-full">
            <JustifiedContentColumn>
                <div className="flex w-full items-center gap-2">
                    <div className="flex grow flex-col items-center gap-2">
                        <CreateOrganizationComponent onSuccess={onSuccess} />
                        {onClose && (
                            <Button variant="link" onClick={onClose} data-track-label="true" data-testid="back-button">
                                Back
                            </Button>
                        )}
                    </div>
                    <QuestionareTimeline steps={onboardingQuestionnaire.steps.steps} />
                </div>
            </JustifiedContentColumn>
        </ClosableWithEsc>
    );
};
