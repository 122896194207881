import { OrgIcon } from "@/components/OrgIcon";
import { Button } from "@/components/flexkit/Button";
import { Label } from "@/components/podkit/forms/Label";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Heading1, Subheading } from "@/components/podkit/typography/Headings";
import { setPrincipal } from "@/principal";
import { useGetAccount } from "@/queries/account-queries";
import { useJoinOrganization, type PlainOrganizationMember } from "@/queries/organization-queries";
import { type PlainMessage } from "@bufbuild/protobuf";
import { type JoinableOrganization } from "gitpod-next-api/gitpod/v1/account_pb";
import { useCallback, type FC } from "react";
import { useNavigate } from "react-router-dom";
import { formatError } from "@/utils/errors";
import { IconPlus } from "@/assets/icons/geist/IconPlus";

export const JoinOrganization: FC = () => {
    const { data: account } = useGetAccount();
    const navigate = useNavigate();

    const joinableOrgs = account?.joinables || [];

    const handleCreateOrganization = useCallback(() => {
        navigate("/create-organization", { state: { from: "join" } });
    }, [navigate]);

    return (
        <div
            data-testid="join-organization"
            className="flex max-h-full w-[500px] flex-col items-center justify-start gap-4 self-center"
        >
            <div className="flex flex-col gap-2 text-center">
                <Heading1>Join an organization</Heading1>
                <Subheading className="pt-1">
                    You can join any of the following organizations or create a new one.
                </Subheading>
            </div>
            <div className="overflow-y-auto pr-2">
                <JoinableOrganizationList orgs={joinableOrgs} />
            </div>
            <Button
                id="create-organization"
                type="submit"
                size={"lg"}
                variant="primary"
                onClick={handleCreateOrganization}
                LeadingIcon={IconPlus}
                className="w-96 items-center justify-center"
            >
                <div className="flex items-center justify-start">
                    <div className="text-base">Create Organization</div>
                </div>
            </Button>
            <a href="/">
                <Button variant={"link"} data-track-label="true">
                    Back
                </Button>
            </a>
        </div>
    );
};

type JoinableOrganizationListProps = {
    orgs: PlainMessage<JoinableOrganization>[];
};

export const JoinableOrganizationList: FC<JoinableOrganizationListProps> = ({ orgs }) => {
    const entries: JSX.Element[] = [];
    for (const org of orgs) {
        entries.push(<JoinableOrganizationListItem key={org.organizationId} org={org} />);
    }
    if (entries.length === 0) {
        entries.push(
            <div key="no-orgs" className="flex h-16 w-full items-center justify-center text-content-secondary">
                No organizations available to join.
            </div>,
        );
    }

    return entries;
};

type JoinableOrganizationItemProps = {
    org: PlainMessage<JoinableOrganization>;
};

export const JoinableOrganizationListItem: FC<JoinableOrganizationItemProps> = ({ org }) => {
    const { toast } = useToast();
    const navigate = useNavigate();

    const joinOrganization = useJoinOrganization();

    const handleJoin = useCallback(
        (org: PlainMessage<JoinableOrganization>) => {
            joinOrganization.mutate(
                { organizationID: org.organizationId },
                {
                    onSuccess: ({ member }: { member: PlainOrganizationMember }) => {
                        setPrincipal(member.userId);
                        navigate("/", { replace: true }); // send user to the dashboard immediately
                        toast({ title: `Joined ${org.organizationName}` });
                    },
                    onError: (error) => {
                        toast({ title: `Cannot join ${org.organizationName}`, description: formatError(error) });
                    },
                },
            );
        },
        [joinOrganization, toast, navigate],
    );
    return <JoinableOrganizationCard org={org} isLoading={joinOrganization.isPending} handleJoin={handleJoin} />;
};

export const JoinableOrganizationCard: FC<{
    org: PlainMessage<JoinableOrganization>;
    isLoading: boolean;
    handleJoin: (org: PlainMessage<JoinableOrganization>) => void;
}> = ({ org, isLoading, handleJoin }) => {
    return (
        <div className="mt-2 flex w-full justify-between rounded-xl border border-border-light bg-surface-glass p-2">
            <div className="flex justify-items-start">
                <div className="flex h-9 w-9 items-center justify-center">
                    <OrgIcon orgName={org.organizationName} />
                </div>
                <div className="flex flex-col justify-center px-2">
                    <Label className="truncate text-base font-bold">{org.organizationName}</Label>
                    {Boolean(org.organizationMemberCount) && (
                        <Label className="text-xs">{org.organizationMemberCount} Members</Label>
                    )}
                </div>
            </div>
            <Button
                type="button"
                variant="primary"
                loading={isLoading}
                onClick={(evt) => {
                    evt.preventDefault();
                    handleJoin(org);
                }}
            >
                Join
            </Button>
        </div>
    );
};
