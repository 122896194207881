import { Button } from "@/components/flexkit/Button";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { Text } from "@/components/podkit/typography/Text";
import { useRunnerEnvironments } from "@/queries/environment-queries";
import { useDeleteRunner, type PlainRunner } from "@/queries/runner-queries";
import { RunnerAdditionalFieldsKeys } from "@/components/runners/details/runner-configuration-keys";
import { formatError } from "@/utils/errors";
import { useCallback, type FC } from "react";

type DeleteRunnerModalProps = {
    runner: PlainRunner;
    onClose: () => void;
};

export const DeleteRunnerModal: FC<DeleteRunnerModalProps> = ({ runner, onClose }: DeleteRunnerModalProps) => {
    const { toast } = useToast();

    const deleteRunner = useDeleteRunner();
    const listEnvironments = useRunnerEnvironments(runner.runnerId);

    const onOpenChange = (open: boolean) => {
        if (!open) {
            onClose();
        }
    };

    const handleDeleteRunner = useCallback(() => {
        deleteRunner.mutate(
            {
                runnerId: runner.runnerId,
                force: false,
            },
            {
                onSuccess: () => {
                    toast({ title: "Runner scheduled for deletion" });
                    onClose();
                },
                onError: (err) => {
                    toast({ title: "Failed to delete runner", description: formatError(err) });
                },
            },
        );
    }, [deleteRunner, onClose, runner, toast]);

    const environmentCount = listEnvironments.data?.environments.length ?? 0;
    const stackURL = runner.status?.additionalInfo.find(
        (info) => info.key === RunnerAdditionalFieldsKeys.StackURL,
    )?.value;

    return (
        <Dialog open onOpenChange={onOpenChange}>
            <DialogContent className="max-w-xl">
                <DialogHeader>
                    <DialogTitle>Delete runner</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <DialogBody>
                    <div className="flex flex-col space-y-2">
                        {environmentCount > 0 ? (
                            <>
                                <Text>All environments associated with this runner will also be deleted.</Text>
                                <Text className="font-bold">
                                    <span>
                                        This will affect {environmentCount} environment
                                        <span>{environmentCount > 1 ? "s" : ""}</span>.
                                    </span>
                                </Text>
                            </>
                        ) : (
                            <Text>There are no environments associated with this runner. </Text>
                        )}
                        <Text>Are you sure you&apos;d like to delete it?</Text>
                        {stackURL && (
                            <div className="space-y-2 rounded-md bg-surface-tertiary p-3">
                                <Text className="font-bold">Important:</Text>
                                <Text>
                                    Remember to also delete the associated AWS CloudFormation stack manually after this
                                    operation.
                                </Text>
                                <Text className="text-sm">
                                    Stack URL:{" "}
                                    <ExternalLink href={stackURL} className="break-all">
                                        {stackURL}
                                    </ExternalLink>
                                </Text>
                            </div>
                        )}
                    </div>
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={onClose}>
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        autoFocus={true}
                        variant="destructive"
                        loading={deleteRunner.isPending}
                        onClick={handleDeleteRunner}
                    >
                        Yes, Delete
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export const ForceDeleteRunnerModal: FC<DeleteRunnerModalProps> = ({ runner, onClose }: DeleteRunnerModalProps) => {
    const { toast } = useToast();

    const deleteRunner = useDeleteRunner();
    const listEnvironments = useRunnerEnvironments(runner.runnerId);

    const onOpenChange = (open: boolean) => {
        if (!open) {
            onClose();
        }
    };

    const handleDeleteRunner = useCallback(() => {
        deleteRunner.mutate(
            { runnerId: runner.runnerId, force: true },
            {
                onSuccess: () => {
                    toast({ title: "Runner force deleted" });
                    onClose();
                },
                onError: (err) => {
                    toast({ title: "Failed to force delete runner", description: formatError(err) });
                },
            },
        );
    }, [deleteRunner, onClose, runner, toast]);

    let withUndeletedText = `${listEnvironments.data?.environments.length} undeleted environments`;
    if (listEnvironments.data?.environments.length === 0) {
        withUndeletedText = "no undeleted environments";
    } else if (listEnvironments.data?.environments.length === 1) {
        withUndeletedText = "1 undeleted environment";
    }

    return (
        <Dialog open onOpenChange={onOpenChange}>
            <DialogContent className="max-w-xl">
                <DialogHeader>
                    <DialogTitle>Warning: Irreversible Action</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <DialogBody>
                    <Text>
                        You are about to permanently and forcibly delete{" "}
                        <span className="text-lg font-bold text-content-primary">&quot;{runner.name}&quot;</span>{" "}
                        {!listEnvironments.isLoading && (
                            <span>
                                with <strong>{withUndeletedText}</strong>.{" "}
                            </span>
                        )}
                        <span>However, please note:</span>
                    </Text>

                    <ul className="list-outside list-disc py-4 pl-6">
                        <li className="pl-1">
                            <span className="text-lg font-bold">Infrastructure and Files</span>: This action does not
                            guarantee the cleanup of any related infrastructure, files, or other dependencies which may
                            continue to exist.
                        </li>
                        <li className="pl-1">
                            <span className="text-lg font-bold">Manual Cleanup Required</span>: You may need to manually
                            verify and clean up these elements.
                        </li>
                    </ul>

                    <Text>Are you sure you want to proceed with Force Delete?</Text>
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={onClose}>
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        autoFocus={true}
                        variant="destructive"
                        loading={deleteRunner.isPending}
                        onClick={handleDeleteRunner}
                    >
                        Yes, Force Delete
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
