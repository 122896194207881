import { useMemo, useState, type FC } from "react";
import { Text } from "@/components/podkit/typography/Text";
import { type PlainSecret } from "@/queries/secret-queries";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/podkit/tables/Table";
import { Timestamp } from "@bufbuild/protobuf";
import { timeAgo } from "@/format/time";
import { Button } from "@/components/flexkit/Button";
import { IconTrash } from "@/assets/icons/geist/IconTrash";
import { useMembers } from "@/queries/organization-queries";
import { DeleteSecretModal } from "@/components/projects/DeleteSecretModal";

export const SecretsTable: FC<{ secrets: PlainSecret[] }> = ({ secrets }) => {
    const [secretToDelete, setSecretToDelete] = useState<PlainSecret | null>(null);

    const sorted = useMemo(() => {
        return [...secrets].sort((a, b) => {
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();
            return aName.localeCompare(bName);
        });
    }, [secrets]);

    return (
        <div
            className="b -surface-glass mt-1 flex flex-col rounded-xl border-[0.5px] border-border-base"
            data-testid="project-secrets"
        >
            <Table className="table-fixed">
                <TableHeader className="h-[52px] [&_th]:border-border-base [&_th]:border-b-[0.5] [&_th]:bg-transparent">
                    <TableRow className="text-sm font-medium">
                        <TableHead className="text-base font-bold text-content-secondary">Name</TableHead>
                        <TableHead className="text-base font-bold text-content-secondary">Created by</TableHead>
                        <TableHead className="text-base font-bold text-content-secondary">Last updated</TableHead>
                        <TableHead className="w-[130px] text-base font-bold text-content-secondary" />
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {sorted.map((secret) => (
                        <SecretRow key={secret.id} secret={secret} onDelete={(secret) => setSecretToDelete(secret)} />
                    ))}
                </TableBody>
            </Table>

            {secretToDelete && <DeleteSecretModal secret={secretToDelete} onClose={() => setSecretToDelete(null)} />}
        </div>
    );
};

const SecretRow: FC<{ secret: PlainSecret; onDelete: (secret: PlainSecret) => void }> = ({ secret, onDelete }) => {
    const { data: members } = useMembers();

    const lastUpdatedDate = useMemo(() => {
        const ts = secret.updatedAt || secret.createdAt;
        if (!ts) {
            return;
        }
        return new Timestamp(ts).toDate();
    }, [secret.updatedAt, secret.createdAt]);

    const lookup = useMemo(() => {
        return Object.fromEntries(members?.members.map((m) => [m.userId, m]) || []);
    }, [members?.members]);

    return (
        <TableRow
            className="h-[52px] border-[0.5px] border-l-0 border-r-0 border-border-base hover:bg-surface-02"
            data-testid={secret.id}
        >
            <TableCell>
                <Text className="truncate font-mono text-base font-bold">{secret.name}</Text>
            </TableCell>
            <TableCell>
                <Text className="truncate text-base">{lookup[secret.creator?.id || ""]?.fullName || "-"}</Text>
            </TableCell>
            <TableCell>
                {lastUpdatedDate ? (
                    <Text className="truncate text-base" title={lastUpdatedDate.toISOString()}>
                        {timeAgo(lastUpdatedDate)}
                    </Text>
                ) : (
                    "-"
                )}
            </TableCell>
            <TableCell>
                <div className="flex gap-2">
                    <Button variant="secondary" disabled={true}>
                        Edit
                    </Button>
                    <Button
                        data-testid={`delete-secret-${secret.id}-button`}
                        LeadingIcon={IconTrash}
                        variant="ghost"
                        className="border-0 px-1"
                        onClick={() => onDelete(secret)}
                    />
                </div>
            </TableCell>
        </TableRow>
    );
};
