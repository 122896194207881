import { useDocumentTitle } from "@/hooks/use-document-title";
import type React from "react";
import { useCallback, useMemo, useState, type FC } from "react";
import {
    useDeleteHostAuthenticationToken,
    useListAllRunnerSCMIntegrations,
    useListHostAuthenticationTokens,
} from "@/queries/runner-configuration-queries";
import { ErrorMessage } from "@/components/ErrorMessage";
import type { PlainMessage } from "@bufbuild/protobuf";
import { HostAuthenticationTokenSource, type SCMIntegration } from "gitpod-next-api/gitpod/v1/runner_configuration_pb";
import { type HostAuthenticationToken } from "gitpod-next-api/gitpod/v1/runner_configuration_pb";
import { cn } from "@/components/podkit/lib/cn";
import { useListRunners } from "@/queries/runner-queries";
import { type Runner, RunnerKind } from "gitpod-next-api/gitpod/v1/runner_pb";
import { Dialog } from "@radix-ui/react-dialog";
import {
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { Text } from "@/components/podkit/typography/Text";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { formatError } from "@/utils/errors";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { useRunnerEnvironments } from "@/queries/environment-queries";
import { CheckIcon, Loader2 } from "lucide-react";
import { IconWarning } from "@/assets/icons/geist/IconWarning";
import { Button } from "@/components/flexkit/Button";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { SourceControlProviderIcon } from "@/components/runners/details/SourceControlProviderIcon";

type RunnerToken = {
    runner: PlainMessage<Runner>;
    token: PlainMessage<HostAuthenticationToken>;
    integration: PlainMessage<SCMIntegration> | undefined;
};

export const GitAuthenticationsPage: FC = () => {
    useDocumentTitle("Git Authentications");
    const {
        data: tokensData,
        error: tokensError,
        isLoading: isLoadingTokens,
        isPending: isPendingTokens,
    } = useListHostAuthenticationTokens();
    const {
        data: runnersData,
        isLoading: isLoadingRunners,
        isPending: isPendingRunners,
    } = useListRunners({ kind: RunnerKind.REMOTE });
    const { data: user, isLoading: isLoadingUser } = useAuthenticatedUser();
    const {
        data: localRunnersData,
        isLoading: isLoadingLocalRunners,
        isPending: isPendingLocalRunners,
    } = useListRunners({
        kind: RunnerKind.LOCAL,
        creatorId: user?.id,
    });

    const allRunners = useMemo(() => {
        return [
            ...(runnersData?.runners || []),
            ...(localRunnersData?.runners || []),
        ];
    }, [runnersData, localRunnersData]);

    const {
        data: integrations,
        isLoading: integrationsLoading
    } = useListAllRunnerSCMIntegrations(allRunners.map((runner) => runner.runnerId));

    const tokens: RunnerToken[] = useMemo(
        () =>
            runnerTokens(tokensData?.tokens || [], allRunners, integrations),
        [tokensData, allRunners, integrations],
    );

    const isLoading =
        isLoadingTokens ||
        isPendingTokens ||
        isLoadingRunners ||
        isPendingRunners ||
        isLoadingUser ||
        isLoadingLocalRunners ||
        isPendingLocalRunners ||
        integrationsLoading;

    if (isLoading) {
        return <LoadingState />;
    }

    return (
        <div data-testid="git-authentications">
            <ErrorMessage error={tokensError} />
            {!tokensError && (
                <div className="flex flex-col gap-2">
                    {tokens.length === 0 && <Text className="text-base">There are no git authentications.</Text>}
                    {tokens.map((rt) => (
                        <GitHostAuthenticationToken key={rt.token.id} token={rt} />
                    ))}
                </div>
            )}
        </div>
    );
};

const GitHostAuthenticationToken: FC<{
    token: RunnerToken;
}> = ({ token }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    return (
        <div
            className="flex items-center justify-between rounded-xl border border-border-light bg-surface-glass px-4 py-2"
            data-testid={`git-authentications-token-${token.token.id}`}
        >
            <span className="w-1/3 text-base font-bold">{token.runner.name}</span>
            <div className="flex w-1/3 items-center gap-2">
                <SourceControlProviderIcon scmId={token.integration?.scmId} size="sm" />
                <span className="text-base font-bold">{token.token.host}</span>
                <HostAuthenticationTokenSourceView source={token.token.source} />
            </div>
            <Button
                loading={false}
                onClick={() => setShowDeleteModal(true)}
                data-testid={`git-authentications-delete-token-${token.token.id}`}
                data-track-label="true"
            >
                Remove
            </Button>
            {showDeleteModal && (
                <HostAuthenticationTokenDeleteModal token={token} onClose={() => setShowDeleteModal(false)} />
            )}
        </div>
    );
};

const HostAuthenticationTokenDeleteModal: FC<{
    token: RunnerToken;
    onClose: () => void;
}> = ({ token, onClose }) => {
    const { toast } = useToast();
    const deleteToken = useDeleteHostAuthenticationToken();
    const listEnvironments = useRunnerEnvironments(token.token.runnerId);

    const onOpenChange = (open: boolean) => {
        if (!open) {
            onClose();
        }
    };

    const handleDeleteToken = useCallback(() => {
        deleteToken.mutate(
            { tokenId: token.token.id },
            {
                onSuccess: () => {
                    toast({ title: "Authentication removed" });
                    onClose();
                },
                onError: (err) => {
                    toast({ title: "Failed to remove authentication", description: formatError(err) });
                },
            },
        );
    }, [deleteToken, onClose, token, toast]);

    return (
        <Dialog open onOpenChange={onOpenChange}>
            <DialogContent
                className="max-w-[400px]"
                data-testid={`git-authentications-delete-token-modal-${token.token.id}`}
            >
                <DialogHeader>
                    <DialogTitle>Remove authentication</DialogTitle>
                    <DialogDescription />
                </DialogHeader>
                <DialogBody className="flex flex-col gap-4">
                    <Text>
                        Remove your access to {token.token.host} for{" "}
                        <span className="font-bold">{token.runner.name}</span> runner?
                    </Text>
                    <div className="mb-1 flex rounded-lg border border-border-light bg-surface-secondary p-4 text-content-secondary">
                        <AffectedEnvironmentsText
                            token={token}
                            isLoading={listEnvironments.isLoading || listEnvironments.isPending}
                            environmentCount={listEnvironments.data?.environments.length ?? 0}
                            error={listEnvironments.error}
                        />
                    </div>
                </DialogBody>
                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={onClose} data-track-label="true">
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        autoFocus={true}
                        variant="destructive"
                        disabled={listEnvironments.isLoading || listEnvironments.isPending}
                        loading={deleteToken.isPending}
                        onClick={handleDeleteToken}
                        data-testid={`git-authentications-delete-token-modal-${token.token.id}-remove`}
                        data-track-label="true"
                    >
                        Yes, Remove
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

const AffectedEnvironmentsText: FC<{
    token: RunnerToken;
    isLoading: boolean;
    environmentCount: number;
    error: Error | null;
}> = ({ token, isLoading, environmentCount, error }) => {
    let icon: React.ReactNode;
    let content: string;

    if (isLoading) {
        icon = <Loader2 strokeWidth={2} className="animate-spin text-content-yield" size={20} />;
        content = "Verifying affected environments...";
    } else if (error) {
        icon = <IconWarning size={"base"} className="text-content-negative" />;
        content = `There was an error while verifying affected environments. Proceed with caution. Any existing environments will be unable to communicate with ${token.token.host} until you re-authenticate.`;
    } else if (environmentCount == 0) {
        icon = <CheckIcon size={20} className="text-content-positive" />;
        content = "Safe to remove.";
    } else {
        icon = <IconWarning size={"base"} className="text-content-yield" />;
        content = `${environmentCount} environment${environmentCount > 1 ? "s" : ""} will be unable to communicate with ${token.token.host} until you re-authenticate.`;
    }

    return (
        <div className="inline-flex items-start justify-start gap-2">
            <div className="flex size-5 items-center">{icon}</div>
            <Text className="text-content-secondary">{content}</Text>
        </div>
    );
};

const HostAuthenticationTokenSourceView: FC<{ source: HostAuthenticationTokenSource }> = ({ source }) => {
    let text = "Unknown";
    switch (source) {
        case HostAuthenticationTokenSource.OAUTH:
            text = "OAuth";
            break;
        case HostAuthenticationTokenSource.PAT:
            text = "Personal Access Token";
            break;
    }

    return (
        <div className={cn("rounded-lg border-0.5 border-border-base bg-surface-tertiary/50 px-2 py-0.5 text-sm")}>
            {text}
        </div>
    );
};

function runnerTokens(
    tokens: PlainMessage<HostAuthenticationToken>[],
    runners: PlainMessage<Runner>[],
    integrations: PlainMessage<SCMIntegration>[],
): RunnerToken[] {
    const runnerById = Object.fromEntries(runners.map((runner) => [runner.runnerId, runner]));
    const integrationByRunnerIdAndHost = Object.fromEntries(
        integrations.map((integration) => [`${integration.runnerId}-${integration.host}`, integration])
    );

    const values: RunnerToken[] = [];
    for (const token of tokens) {
        const runner = runnerById[token.runnerId] || undefined;
        const integration = integrationByRunnerIdAndHost[`${token.runnerId}-${token.host}`] || undefined;
        if (runner) {
            values.push({
                token,
                runner,
                integration,
            });
        }
    }
    return values;
}
