import { SkeletonBlock, SkeletonText } from "@/components/podkit/loading/Skeleton";
import { RunnerDetailsSection, type SectionStatus } from "@/components/runners/details/RunnerDetailsSection";
import { Text } from "@/components/podkit/typography/Text";
import { useCallback, useEffect, useMemo, useState, type FC } from "react";
import { useListRunnerPolicies, useUpdateSharingSetting } from "@/queries/runner-queries";
import { cn } from "@/components/podkit/lib/cn";
import { RadioGroup, RadioGroupItem } from "@/components/podkit/forms/RadioListField";
import { useOrganization } from "@/queries/organization-queries";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { formatError } from "@/utils/errors";
import { useListGroups } from "@/queries/group-queries";
import { ErrorMessage } from "@/components/ErrorMessage";
import { useMembership } from "@/hooks/use-membership";
import { OrganizationRole } from "gitpod-next-api/gitpod/v1/organization_pb";

const GroupnNames = {
    OrgMembers: "org-members",
};

const SharingSetting = {
    OnlyMe: "only-me",
    AnyoneInOrg: "anyone-in-org",
    SpecificPeople: "specific-people",
};

export const RunnerSharing: FC<{ runnerId: string; hasStack: boolean }> = ({ runnerId, hasStack }) => {
    const [sharing, setSharing] = useState<string>(SharingSetting.OnlyMe);

    const { toast } = useToast();
    const { data: policies, isLoading: isLoadingPolicies, error: policiesError } = useListRunnerPolicies(runnerId);
    const { data: organization, isLoading: isLoadingOrganization, error: organizationError } = useOrganization();
    const { data: groups, isLoading: isLoadingGroups, error: groupsError } = useListGroups();
    const { membership, isPending: isLoadingMembership } = useMembership();

    const hasError: boolean = !!policiesError || !!organizationError || !!groupsError;
    const isLoading = isLoadingPolicies || isLoadingOrganization || isLoadingGroups || isLoadingMembership;

    const orgMembersGroup = useMemo(() => {
        if (!organization || !groups) {
            return;
        }
        return groups.groups.find((g) => g.organizationId === organization.id && g.name === GroupnNames.OrgMembers);
    }, [organization, groups]);

    const updateSharingSetting = useUpdateSharingSetting(runnerId);

    let status: SectionStatus = "loading";
    if (!isLoading) {
        if (!hasStack) {
            status = "locked";
        } else {
            status = "optional";
        }
    }

    const readOnly = status === "locked" || membership?.userRole !== OrganizationRole.ADMIN;

    const onSharingSettingChange = useCallback(
        async (value: string) => {
            if (!orgMembersGroup) {
                return;
            }
            if (value === "only-me" || value === "anyone-in-org") {
                setSharing(value);
                try {
                    await updateSharingSetting.mutateAsync({ setting: value, groupId: orgMembersGroup?.id });
                    toast({
                        title: "Sharing updated",
                        description:
                            value == "only-me"
                                ? "Runner is now only available to you."
                                : "Runner is now available to anyone in your organization.",
                    });
                } catch (error) {
                    setSharing(sharing);
                    toast({
                        title: "Failed to update sharing setting",
                        description: formatError(error),
                    });
                }
            }
        },
        [orgMembersGroup, sharing, toast, setSharing, updateSharingSetting],
    );

    useEffect(() => {
        if (policies && orgMembersGroup) {
            const sharedWithOrg = policies.policies.find((p) => p.groupId === orgMembersGroup?.id);
            setSharing(sharedWithOrg ? SharingSetting.AnyoneInOrg : SharingSetting.OnlyMe);
        }
    }, [policies, setSharing, orgMembersGroup]);

    return (
        <RunnerDetailsSection status={status}>
            <div className="flex flex-col gap-4" data-testid="sharing-section">
                <div className="flex flex-col gap-1">
                    <SkeletonText ready={status !== "loading"} size="lg" className="w-48">
                        <Text className={cn(status === "locked" && "text-content-secondary", "text-lg font-bold")}>
                            Sharing
                        </Text>
                        <Text className={cn(status === "locked" && "text-content-secondary", "text-base")}>
                            Specify who can use this runner
                        </Text>
                    </SkeletonText>
                </div>
                <SkeletonBlock ready={!isLoading} failed={hasError} className="mt-1 h-[100px]">
                    {!hasError && (
                        <RadioGroup value={sharing} onValueChange={onSharingSettingChange}>
                            <div className="flex items-center justify-start gap-2">
                                <RadioGroupItem
                                    className="h-4 w-4"
                                    value={SharingSetting.OnlyMe}
                                    id={SharingSetting.OnlyMe}
                                    disabled={readOnly}
                                />
                                <label htmlFor={SharingSetting.OnlyMe} className="cursor-pointer">
                                    <Text className="text-base">Only me</Text>
                                </label>
                            </div>

                            <div className="flex items-center justify-start gap-2">
                                <RadioGroupItem
                                    className="h-4 w-4"
                                    value={SharingSetting.AnyoneInOrg}
                                    id={SharingSetting.AnyoneInOrg}
                                    disabled={readOnly}
                                />
                                <label htmlFor={SharingSetting.AnyoneInOrg} className="cursor-pointer">
                                    <Text className="text-base">Anyone in {organization?.name}</Text>
                                </label>
                            </div>

                            <div className="flex items-center justify-start gap-2">
                                <RadioGroupItem
                                    className="h-4 w-4"
                                    value={SharingSetting.SpecificPeople}
                                    id={SharingSetting.SpecificPeople}
                                    disabled={true}
                                />
                                <label
                                    htmlFor={SharingSetting.SpecificPeople}
                                    className="flex cursor-pointer items-center justify-center gap-2"
                                >
                                    <Text className="text-base">Specific people</Text>
                                    <div className="rounded-3xl border border-border-light bg-surface-positive px-2 py-1">
                                        <Text className="text-sm">Coming soon</Text>
                                    </div>
                                </label>
                            </div>
                        </RadioGroup>
                    )}
                </SkeletonBlock>
                <ErrorMessage error={policiesError} />
                <ErrorMessage error={organizationError} />
                <ErrorMessage error={groupsError} />
            </div>
        </RunnerDetailsSection>
    );
};
